/* this file added by Bearer Web Developer  */
.google_review {
    padding: 16px;
    border-radius: 10px;
    border: 2px solid rgba(227, 227, 227, 0.20);
    background: var(--neutral-hsb-96, #F5F5F5);
    display: flex;
    gap: 12px;
    flex-direction: column;
    
    .review_part {
        display: flex;
        align-items: center;
      
    }

    .google_part {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
            margin: 0px;
            padding: 0px;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
    }
}


@media screen and (max-width: 680px) {
    .google_review {
        flex-direction: row;
        flex-wrap: wrap;
    }
}