/* this file added by Bearer Web Developer  */
.tooltip {
    background-color: white !important;
    color: var(--neutral-hsb-30, #4D4D4D) !important;
    /* Bold */
    font-family: GothamBold !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}

.arrow {
    color: white !important;
}

.mainContainer {
    padding: 0;

    .circular_progress {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    .container {
        display: flex;

    }

    .adjustable {
        display: block;

        &::after {
            content: '';
            width: 20px;
            height: 95px;
            position: absolute;
            top: calc(50% - 42.5px);
            left: 8px;
            background: url('./../assets/images/adjustable-button.png');
        }
    }

    .orderForm_container {
        background: var(--Secondary-2, #14438E);
        height: 100vh;
        position: relative;
        width: 100%;
        //max-width: 354px; // 346px (width) + 8px (scroll bar)
        max-width: 500px;
        min-width: 308px;

        .orderForm {
            max-height: calc(100vh);
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            background: var(--Secondary-2, #14438E);
            height: 100vh;

            .bearer_header {
                position: relative;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 5px;
                align-items: center;
                padding: 30px 5px 31px 5px;

                .whatsapp {
                    a {
                        display: flex;
                        justify-content: end;
                        align-items: center;

                        .whatsapp_image {
                            margin-right: 20px;
                        }
                    }
                }

                .login_menu {
                    

                    .login_mobile {
                        display: none;
                        cursor: pointer;
                        margin-left: 16px;
                        max-width: 44px;
                        width: 44px;
                        min-width: 44px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        background: var(--neutral-hsb-96, #F5F5F5);
                        margin-right: 38px;
                        padding: 5px;
                        color: var(--Secondary-2, #14438E);
                        /* Bold */
                        font-family: GothamBold;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 26px;
                    }
                    .complete_profile_menu {
                        z-index: 1;
                        display: none;
                    }
                    .hamburger_menu {
                        display: none;
                        justify-content: start;
                        align-items: center;
                        margin-left: 16px;

                        span {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 11px;
                            border-radius: 10px;
                            background: var(--Neutral, #FFF);
                            box-shadow: 0px 38px 31px -27px rgba(47, 47, 47, 0.37);
                            cursor: pointer;
                            color: white;
                        }
                    }
                }

            }
        }


    }


    .map_container {
        width: 100%;
        //margin-left: 20px;
        position: relative;

        .map_mobile_logout {
            display: block;
        }

        .map {
            position: relative;
            height: calc(100vh - 87px);
            flex-grow: 1;
            top: 87px;

            .swipable {
                display: none;
            }

            .bearer_map {
                height: 100%;
            }

            .bearer_map_mobile {
                display: block;
            }
        }
    }

    .logo {
        // width: 150px;
        margin: 10px,
    }

    .root_tabs {
        border-radius: 10px 10px 0 0;
        margin: 0;
        padding: 0;
    }

    .flex_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .flex_container_more_than_one_tabs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        .tab_main {
            min-width: 100%;
        }

        .tab {
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
            background: var(--secondary-2-hsb-82, #1D63D1);
        }

        .reserved_two_tab {
            font-size: 14px;
        }

        .order_one {
            border-radius: 10px 0px 0px 0px;
        }

        .one_tab {
            // width: 169.86px; // for 354px max-width left side
            width: 100%; // for 500px max-width left side

            padding: 27px 0 26px 0;
        }

        .more_than_one_tabs {
            width: 100%;
            padding: 27px 27px 26px 28px;
        }

        .more_tabs,
        .more_tabs_three {
            width: 100%;
            padding: 27px 27px 26px 28px;
            border-radius: 0px;
        }

        .add_order {
            border-radius: 0px 10px 0px 0px;
            background: var(--secondary-2-hsb-66, #184FA8);
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Regular */
            font-family: GothamRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            /* 162.5% */

        }

        .add_order_one_tab {
            // width: 169.86px; // for 354px max-width left side
            width: 100%; // for 500px max-width left side
            padding: 27px 0 26px 0;

        }

        .add_order_more_than_one_tabs {
            width: 100%;
            padding: 27px 32px 26px 32px;
        }

        .add_order_more_tabs,
        .add_order_more_tabs_three {
            width: 100%; // for 500px max-width left side
            padding: 27px 32px 26px 32px;
            border-radius: 0px;
        }

        .tab_indicator {
            width: 100%;
            background-color: black;
        }

        .scroll_buttons {
            z-index: 1;
            background: var(--neutral-hsb-96, #F5F5F5);
            padding: 0 5px;
            cursor: pointer;
            height: 79px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .scroll_button_left {
            //background: linear-gradient(270deg, #1D63D1 -17%, rgba(29, 99, 209, 0.00) 87%);
            box-shadow: 8px 0px 20px 20px #1d63d1;
            border-radius: 10px 0px 0px 0px;
        }

        .scroll_button_right {
            border-radius: 0px 10px 0px 0px;
            box-shadow: 0px 8px 20px 20px #1d63d1;
        }
    }

    .loader {
        display: block;
        margin: 40px;
    }

    .BrowserLogo {
        width: 100px;
        height: 100px;
    }

    .browsersContainer {
        text-align: center;
    }
}


@media screen and (max-width: 834px) {

    .mainContainer {
        .container {
            display: block;

        }

        .orderForm_container {
            max-width: 100%;
            min-width: 100%;

            .orderForm {
                max-height: unset;
                height: unset;

                .bearer_header {
                    position: relative;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 5px;
                    align-items: center;

                    .whatsapp {
                        a {
                            display: flex;
                            justify-content: end;
                            align-items: center;

                            .whatsapp_image {
                                margin-right: 20px;
                            }
                        }
                    }

                    .login_menu {
                        .login_mobile {
                            display: flex;
                        }
                        .complete_profile_menu {
                            display: block;
                        }
                        .hamburger_menu {
                            display: flex;
                        }
                    
                        z-index: 1;
                    }
                }
            }
        }

        div:has(>.adjustable) {
            /* access to the parent */
            display: none;
        }

        .tabs {
            .more_tabs_three {
                width: 200px;
            }

            .add_order_more_tabs_three {
                width: 200px;
            }
        }

        .map_container {
            width: 100%;

            .map_mobile_logout {
                display: none;
            }

            .map {
                background: var(--neutral-hsb-89, #E3E3E3);
                padding: 30px;
                position: fixed;
                height: 295px;
                border-radius: 20px 20px 0 0;
                bottom: 0px;
                top: unset;
                width: 100%;

                .swipable {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--neutral-hsb-89, #E3E3E3);
                    width: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0;
                    border-radius: 20px 20px 0 0;

                    p {
                        margin: 0;
                    }

                    .bar {
                        width: 48px;
                        height: 3px;
                        flex-shrink: 0;
                        background-color: #bdbdbd;
                        margin-top: 12px;
                        margin-bottom: 12px;
                    }

                    .swipe_up {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 21px;
                        padding-bottom: 21px;

                        img {
                            margin-right: 8px;
                        }

                        p {
                            color: var(--Secondary-2, #14438E);
                            text-align: center;
                            /* Bold */
                            font-family: GothamBold;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 16px;
                            /* 114.286% */
                        }
                    }
                }

                .bearer_map {
                    height: 100%;
                    border-radius: 20px;
                    border: 2px solid var(--Primary, #009EFF);
                }

                .bearer_map_mobile {
                    display: none;
                }
            }

            .map_mobile {
                top: calc(100% - 66px);
            }

        }
    }

}

@media screen and (max-width: 500px) {
    .mainContainer {
        .tabs {
            .more_tabs_three {
                width: 100%;
            }

            .add_order_more_tabs_three {
                width: 100%;
            }
        }

        .orderForm_container {

            .orderForm {
                margin-bottom: 90px;
            }
        }
    }
}