/* this file added by Bearer Web Developer  */

.important_note {
    border-radius: 6px;
    background: var(--primary-2-hsb-100, #FFCDA4);
    padding: 10px;
    padding-right: 16px;
    padding-bottom: 16px;
    margin-bottom: 65px;

    h3 {
        display: flex;
        align-items: center;
        color: var(--neutral-hsb-15, #262626);
        /* Bold */
        font-family: GothamBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    ul {
        margin: 0;
        padding: 0;
        padding-left: 20px;

        li {
            padding-left: 4px;
            color: var(--neutral-hsb-15, #262626);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            list-style-image: url("./../../../../../assets/images/circle-note.svg");
        }
    }
}