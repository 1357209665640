/* this file added by Bearer Web Developer  */
.mobile_code_data {
    margin-top: 26px;
    margin-bottom: 26px;

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }

    .label {
        margin-bottom: 8px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
            font-family: GothamBold;
        }
    }

    .field {
        display: flex;
        align-self: center;
        gap: 12px;

    }
}