/* this file added by Bearer Web Developer  */
.root {

    width: 468px;
    max-width: 100%;
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;
            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }

    }

    p {
        color: var(--Secondary-2, #14438E);
        margin-top: 8px;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .confirm {
        display: flex;
        justify-content: space-between;

        .no {
            padding-top: 10.4px;
            padding-bottom: 10.4px;
            margin-right: 14px;
            width: 100%;
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
            text-align: center;

            span {
                color: var(--neutral-hsb-96, #F5F5F5);

                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }

            &:hover {
                background: var(--secondary-2-hsb-29, #0A234A);
            }

            &:active {
                outline: 4px solid var(--secondary-2-hsb-66, #184FA8);
                background: var(--secondary-2-hsb-29, #0A234A);
            }
        }

        .yes {
            padding-top: 10.4px;
            padding-bottom: 10.4px;
            width: 100%;
            border-radius: 10px;
            border: 2px solid var(--Secondary-2, #14438E);
            text-align: center;

            span {
                color: var(--Secondary-2, #14438E);

                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }

            &:hover {
                span {
                    color: var(--neutral-hsb-96, #F5F5F5);
                }

                background: var(--secondary-2-hsb-29, #0A234A);
            }

            &:active {
                span {
                    color: var(--neutral-hsb-96, #F5F5F5);
                }

                outline: 4px solid var(--secondary-2-hsb-66, #184FA8);
                background: var(--secondary-2-hsb-29, #0A234A);
            }
        }
    }
}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }
}