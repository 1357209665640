/* this file added by Bearer Web Developer  */

.remove_confirm_menu {
    display: flex;
    flex-direction: column;
    padding: 4px 12px;
    max-width: 210px;
    h4 {
        margin-top: 0px;
        margin-bottom: 8px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Light */
        font-family: GothamLight;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .confirm {
        display: flex;
        gap: 8px;
        .no {
            width: 100%;
            color: var(--Secondary-2, #14438E);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 12px;
            border-radius: 6px;
            outline: 1px solid var(--Secondary-2, #14438E);
        }
    
        .yes {
            width: 100%;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: Gotham;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 12px;
            border-radius: 6px;
            background: var(--Secondary-2, #14438E);
        }
    }
}