/* this file added by Bearer Web Developer  */
.root {
    margin-left: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
        padding: 6px 10px;
        margin-right: 16px;
        border-radius: 10px;
        background: var(--neutral-hsb-89, #E3E3E3);

        span {
            color: var(--Secondary-2, #14438E);
            /* Regular */
            font-family: GothamRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            /* 162.5% */
        }

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            width: 35px;
            height: 35px;
            flex-shrink: 0;
            border-radius: 8px;
            background: var(--neutral-hsb-93, #EDEDED);
        }

        &:disabled {
            opacity: 0.3;
        }

        &:hover {
            background: var(--Secondary-2, #14438E);

            span {
                color: var(--neutral-hsb-93, #EDEDED);
            }

            .icon {
                background: var(--secondary-2-hsb-70100, #4E92FF);

                svg {
                    path {
                        fill: #F5F5F5;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 834px) {
    .root {
        margin-left: 16px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 17px;

        .button {
            order: 2;
            margin-right: 16px;
            justify-content: flex-start;
        }
    }
}