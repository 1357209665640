/* this file added by Bearer Web Developer  */
.account_form {
    .input {
        margin-top: 26px;
        margin-bottom: 26px;

        .label {
            margin-bottom: 8px;
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            span {
                font-family: GothamBold;
            }
        }

        .field {
            display: flex;
            align-self: center;
            gap: 12px;

            .restart {
                display: flex;
                align-self: center;
                justify-content: center;
                padding: 10px;
                border-radius: 10px;
                background: var(--neutral-hsb-89, #E3E3E3);
            }

            .second_part {
                width: 100%;
                max-width: 114px;
                .second {
                    border-radius: 10px;
                    background: var(--primary-3-hsb-70100, #4C91FF);
                    color: var(--neutral-hsb-96, #F5F5F5);
                    text-align: center;
                    /* Bold */
                    font-family: GothamBold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px;
                    /* 162.5% */
                    padding: 12px;
                    width: 100%;
                }
            }
        }
    }

    .button {
        width: 100%;
        border-radius: 10px;
        background: var(--Secondary, #14438E);
        padding: 12px;

        &:disabled {
            opacity: 0.5;
        }

        span {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }

        &:active {
            outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }

}