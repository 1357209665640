/* this file added by Bearer Web Developer  */
.root {
    width: 468px;
}

.payment {
    max-width: 468px;
    width: 100%;
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        h3 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .prices {
            display: flex;
            align-items: center;

            .real_price {
                margin: 0;
                color: var(--neutral-hsb-52, #858585);
                text-align: right;
                /* Light */
                font-family: GothamLight;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                text-decoration: line-through;
                margin-right: 7px;
            }

            .discounted_price {
                margin: 0;
                color: var(--16971-b, #16971B);
                text-align: right;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
        }

        .real_price_no_discounted {
            margin: 0;
            color: var(--Primary-2, #FF8A29);
            text-align: right;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
    }

    .show_cards {
        .accordion {
            .accordion_details {
                display: block;
                width: 100%;
                padding: 0;
            }

            .accordion_summary_root {
                flex-direction: column;
                padding: 0;
            }

            .accordion_summary_content {
                margin: 0;
                width: 100%;
            }

            .accordion_summary_expanded {
                transform: rotate(0deg);

                img {
                    transform: rotate(180deg);
                }
            }

            .accordion_summary_expand_icon {
                width: 100%;
                padding: 0;
                margin-right: 0;

                .expand_icon {
                    width: 100%;
                    padding: 13px 15px 11px 16px;

                    h6 {
                        margin: 0;
                        color: var(--Neutral, #FFF);
                        /* Regular */
                        font-family: GothamRegular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 10px;
                    background: var(--primary-linear, linear-gradient(218deg, #009EFF 2.84%, #46AAE8 101.77%));
                }
            }

            .accordion_summary {
                p {
                    margin: 0;
                    margin-top: 8px;
                    margin-bottom: 26px;
                    color: var(--Secondary-2, #14438E);
                    /* Bold */
                    font-family: GothamBold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px;
                    /* 162.5% */
                }
            }
        }

        .card_options {
            margin-top: 22px;

            .cards_container {
                border-radius: 10px;
                background: var(--neutral-hsb-89, #E3E3E3);
                margin-top: 12px;
                margin-bottom: 12px;
                min-height: 66px;

                .text {
                    color: var(--neutral-hsb-52, #858585);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-right: 12px;
                }

                .text_date {
                    color: var(--neutral-hsb-52, #858585);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .expire_card {
                    margin-right: 10px;
                    margin-left: 10px;
                    color: var(--Red, #DF402A);
                    /* Bold */
                    font-family: GothamBold;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .delete_button {
                    width: 38px;
                    height: 38px;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background: var(--Red, #DF402A);
                }

                .delete_confirm {
                    padding: 6px 8px;
                    color: white;
                    background-color: red;
                    border-radius: 3px;
                }
            }

            .cards_container_selected {
                border-radius: 10px;
                background: var(--secondary-2-hsb-70100, #4E92FF);
                margin-top: 12px;
                margin-bottom: 12px;
                min-height: 66px;

                .card_information_visa {
                    svg path {
                        fill: #EDEDED;
                    }
                }

                .text {
                    color: var(--neutral-hsb-89, #E3E3E3);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-right: 12px;
                }

                .text_date {
                    color: var(--neutral-hsb-89, #E3E3E3);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .delete_button {
                    width: 38px;
                    height: 38px;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background: var(--neutral-hsb-85, #D9D9D9);

                    svg path {
                        fill: #DF402A;
                    }
                }

                .delete_confirm {
                    padding: 6px 8px;
                    color: white;
                    background-color: red;
                    border-radius: 3px;
                    height: 38px;
                }
            }
        }

        .circular_progress {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .payment_actions {
            margin-top: 26px;

            .cancel {
                border-radius: 10px;
                border: 2px solid var(--Secondary-2, #14438E);
                color: var(--Secondary-2, #14438E);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
                &:hover {
                    background: var(--secondary-2-hsb-29, #0A234A);
                    color: var(--neutral-hsb-96, #F5F5F5);
                }

                &:active {
                    outline: 4px solid var(--secondary-2-hsb-66, #184FA8);
                    background: var(--secondary-2-hsb-29, #0A234A);
                    color: var(--neutral-hsb-96, #F5F5F5);
                }
            }

            .pay {
                border-radius: 10px;
                background: var(--Secondary-2, #14438E);
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
                &:disabled {
                    background: var(--neutral-hsb-74, #BDBDBD);
                }

                &:hover {
                    background: var(--secondary-2-hsb-29, #0A234A);
                }

                &:active {
                    outline: 4px solid var(--secondary-2-hsb-66, #184FA8);
                    background: var(--secondary-2-hsb-29, #0A234A);
                }
            }
        }
    }
}
@media screen and (max-width: 680px) {
    .payment {  
        padding: 30px;
    }
}