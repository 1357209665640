/* this file added by Bearer Web Developer  */
.disabled {
    opacity: 0.7;
}

.text_input {
    width: 100%;

    .label {
        padding-bottom: 8px;
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .input_part {
        position: relative;

        input {
            /* Light */
            font-family: GothamLight;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 23.5px;
            &::placeholder {
                /* Light */
                font-family: GothamLight;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            border: none;
            border-radius: 10px;
            height: 48px;
            padding: 19px 48px 19px 48px;
            width: 100%;

            &:hover,
            &:active,
            &:focus+.close_clear {
                opacity: 1;
            }
        }

        .close_clear {
            opacity: 0;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
        }

        &:hover,
        &:active,
        &:focus {
            .close_clear {
                opacity: 1;
            }
        }
    }

    .input_small {
        input {
            /* Light */
            font-family: GothamLight;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 31px;
            padding: 10px 37px 10px 12px;
            &::placeholder {
                /* Light */
                font-family: GothamLight;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .close_clear {
            top: 3.5px;
        }
    }

    .error {
        color: red;
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        img {
            margin-right: 6px;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
        }

        .error_blue {
            color: var(--neutral-hsb-89, #E3E3E3);
            /* Book */
            font-family: Gotham;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 117.233%;
            /* 11.723px */
        }
    }

    .blue {
        .label {
            color: var(--neutral-hsb-96, #F5F5F5);
        }

        input {
            background: var(--secondary-2-hsb-70100, #4E92FF);
            color: var(--neutral-hsb-96, #F5F5F5);

            &::placeholder {
                color: var(--neutral-hsb-96, #F5F5F5);
            }

            outline: none;

            &:focus {
                border: 2px solid var(--neutral-hsb-96, #F5F5F5);
            }
        }
    }

    .gray {
        .label {
            color: var(--neutral-hsb-30, #4D4D4D);
        }

        input {
            background: rgba(189, 189, 189, 0.5);
            color: var(--neutral-hsb-15, #262626);
            &::placeholder {
                color: var(--neutral-hsb-15, #262626);
            }
        }
    }

    .white {

        .label {
            color: var(--neutral-hsb-30, #4D4D4D);

        }

        input {
            background: var(--neutral-hsb-89, #E3E3E3);
            color: var(--neutral-hsb-15, #262626);

            &::placeholder {
                /* Most modern browsers support this now. */
                color: var(--neutral-hsb-15, #262626);

            }


        }
    }

}



@media screen and (max-width: 610px) {
    .text_input {
        input {
            letter-spacing: 17px;
            padding: 19px 28px 19px 28px;
        }
        .gray { 
            input {
                padding: 19px 28px 19px 28px;
            } 
        }
    }
}
