/* this file added by Bearer Web Developer  */
.time {
    opacity: 0.5;
    width: 100%;
    padding: 15px;
    border-radius: 6px;
    background: var(--neutral-hsb-85, #D9D9D9);

    .date {
        margin-bottom: 8px;
    }
    .date,
    .time_value {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}