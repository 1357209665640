/* this file added by Bearer Web Developer  */
.root {
    margin: 16px;

    .main {
        border-radius: 10px;
        background: var(--secondary-2-hsb-82, #1D63D1);
        padding: 18px;

        .header {
            display: flex;
            justify-content: space-between;

            .destination {
                display: flex;

                img {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                }

                h2 {
                    margin: 0px;
                    color: var(--neutral-hsb-96, #F5F5F5);
                    /* Bold */
                    font-family: GothamBold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px;
                    /* 162.5% */
                }
            }

            .edit {
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: right;
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

        }

        .description {
           
            p {
                margin-top: 25px;
                padding: 0;
                color: var(--neutral-hsb-96, #F5F5F5);
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}