/* this file added by Bearer Web Developer  */
.verified_part {
    .verified {
        border-radius: 10px;
        background: var(--Style, #16971B);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        padding: 11px;
    }
}
.restart_part {
    .restart {
        display: flex;
        align-self: center;
        justify-content: center;
        padding: 10px;
        border-radius: 10px;
        background: var(--primary-38666, #184FA8);
        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }
        &:disabled {
            background: var(--neutral-hsb-89, #E3E3E3);
        }
    }
}