/* this file added by Bearer Web Developer  */
.information {
    padding: 14px;
    border-radius: 6px;
    background: var(--neutral-hsb-89, #E3E3E3);
    ul {
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        li {
            list-style-type: none;
            display: flex;
            gap: 9px;
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            .recipient_name_input,.recipient_number_input {
                padding-top: 2px;
                width: 100%;
            }
        }
        li.parcel_weight {
            opacity: 0.5;
           flex-direction: column;

        }
    }
}

@media screen and (max-width: 680px) {
    .information {
        ul {
  
            grid-template-columns: 1fr;
        }
    }
}