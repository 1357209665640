/* this file added by Bearer Web Developer  */
.discount_code {
    margin-left: 22px;

    .discount_code_button {
        padding: 0;

        &:hover {
            background: unset;
        }

        span.text {
            border-radius: 10px;
            background: var(--neutral-hsb-89, #E3E3E3);
            padding: 15px 8px;
            color: var(--Secondary-2, #14438E);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

        }
    }
}

.discount_code_dialog {
    padding: 44px;
    width: 468px;
    max-width: 100%;
    border-radius: 10px;
    background: var(--neutral-hsb-96, #F5F5F5);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;

            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }
    }

    .text {

        h6 {
            margin: 8px 0 6px 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
    }
    .promo_code_input {
        padding-top: 20px;
        .error {
            color: red;
            margin-top: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
    
            img {
                margin-right: 6px;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
            }
        }
    }

    .apply {
        margin-top: 26px;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        width: 100%;
        padding: 12px 0px;

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }

        &:active {
            outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }

    .apply:disabled {
        opacity: 0.5;
        color: var(--neutral-hsb-96, #F5F5F5);
    }

}

@media screen and (max-width: 680px) {

    .discount_code_dialog {
        padding: 30px;
    }
}