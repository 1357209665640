/* this file added by Bearer Web Developer  */
.root {

    width: 594px;
    max-width: 100%;
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;

            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }

    }

    p {
        color: var(--Secondary-2, #14438E);
        margin-top: 8px;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .content_box {
        padding-right: 44px;

    }


    .generate_api_secret {
        position: sticky;
        bottom: 0;
        padding-bottom: 44px;
        background-color: var(--neutral-hsb-96, #fff);

        // box-shadow: 0px -20px 20px 1px #fff;
        .generate_api_secret_button_disabled {
            width: 100%;
            padding: 12px;
            border-radius: 10px;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            background: var(--neutral-hsb-66, #A8A8A8);
            cursor: not-allowed;
        }
    }

}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }
}