/* this file added by Bearer Web Developer  */
.delete_acount {
    margin: 44px;

    .header {
        display: flex;
        justify-content: space-between;

        h6 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;

            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }
    }

    p {
        margin: 0;
        margin-top: 16px;
        color: var(--Secondary-2, #14438E);

        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;

        /* 162.5% */
        .link {
            color: var(--Primary, #009EFF);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
    }
}

@media screen and (max-width: 680px) {
    .delete_acount {
        margin: 30px;
    }
}