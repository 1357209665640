/* this file added by Bearer Web Developer  */
.paper_width_sm {
    max-width: 678px;
    width: 100%;
}

.favourite_places {
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;

        h3 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .add_and_cancel {
            display: flex;

            .add {
                color: var(--Neutral, #FFF);
                text-align: right;
                /* Regular */
                font-family: GothamRegular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                /* 162.5% */
                border-radius: 10px;
                background: var(--Secondary-2, #14438E);
                margin-right: 10px;
                &:hover {
                    background: var(--Secondary-2, #0a234c);
                }
                &:active {
                    outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
                    background: var(--secondary-2-hsb-29, #0A234A);
                }
            }

            .cancel {
                padding: 0;
                justify-content: end;
                min-width: unset;

                &:hover {
                    svg path {
                        fill: #4D4D4D;
                    }
                }
            }


        }
    }

    .favourite_place {
        border-radius: 10px;
        background: var(--neutral-hsb-89, #E3E3E3);
        margin: 10px 0;
        padding: 16.4px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        transition: all 0.5s;
        &:active {
            background: var(--neutral-hsb-74, #BDBDBD);
        }
        .favourite_place_info {
            display: flex;
            align-items: center;
            .location_favourite_place {
                margin-right: 14px;
                margin-left: 22px;
            }
        
            .list_item_text {
                h6 {
                    margin: 0;
                    margin-bottom: 12px;
                    color: var(--neutral-hsb-30, #4D4D4D);
                    /* Bold */
                    font-family: GothamBold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
        
                p {
                    margin: 0;
                    color: var(--neutral-hsb-30, #4D4D4D);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

}
@media screen and (max-width: 680px) {
    .favourite_places {
        padding: 30px;
    }
}