/* this file added by Bearer Web Developer  */
.account_form {

    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 26px 24px; // row gap and column gap
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            border-radius: 10px;
            padding: 13px 14px 13px 14px;
            cursor: pointer;
            background: var(--neutral-hsb-92, #EAEAEA);
            display: flex;
            align-items: center;
            gap: 12px;

            .circle {
                min-width: 50px;
                width: 50px;
                height: 50px;
                background: var(--neutral-hsb-89, #E3E3E3);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            h5 {
                margin-top: 0px;
                margin-bottom: 0px;
                color: var(--neutral-hsb-30, #4D4D4D);
                font-family: Gotham;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 19px;
                /* 135.714% */
            }
        }

        li.active {
            background: var(--secondary-2-hsb-82, #1D63D1);

            .circle {
                background: var(--secondary-2-hsb-66, #184FA8);
            }

            h5 {
                color: var(--neutral-hsb-96, #F5F5F5);
            }
        }
    }

    .input {
        margin-top: 26px;
    }

    .button {
        width: 100%;
        margin-top: 22.72px;
        border-radius: 10px;
        background: var(--Secondary, #14438E);
        padding: 12px;

        &:disabled {
            opacity: 0.5;
        }

        span {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }

        &:active {
            outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }

}