/* this file added by Bearer Web Developer  */
.end_step {
    
    .header {
        display: flex;
        justify-content: space-between;

        h5 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        img {
            width: 28px;
            height: 28px;
            cursor: pointer;
        }
    }

    .content {
        margin-top: 23px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        p {
            margin-top: 0px;
            margin-bottom: 0px;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        img {
            border-radius: 8px;
        }

    }


    .submit {
        margin-top: 26px;
        display: flex;
        width: 100%;
        height: 50px;
        padding: 12px 0px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }
    .submit:hover {
        background: var(--secondary-2-hsb-29, #0A234A);
    }

}