/* this file added by Bearer Web Developer  */
.main {
    width: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .step_content {
            display: flex;
            align-items: center;

            .step_number {
                color: var(--Primary-3, #14438E);
                font-family: GothamBold;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 32.5px;

                span {
                    color: var(--primary-3-hsb-70100, #4C91FF);
                    font-family: GothamBold;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    /* 133.333% */
                }
            }
        }
    }

    p {
        margin-top: 7px;
        margin-bottom: 0px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
    }

    .step_show {
        border-radius: 18px;
        position: relative;
        margin-top: 26px;
        height: 5px;
        background: var(--neutral-hsb-85, #D9D9D9);

        .fill {
            border-radius: 18px 0px 0px 18px;
            background: var(--primary-3-hsb-70100, #4C91FF);
            height: 100%;
            width: 100%;
        }
    }
}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }
}