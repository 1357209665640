/* this file added by Bearer Web Developer  */
.paper_width_sm {
    max-width: 632px !important;
}
.end_step_root {
    padding: 44px;
    border-radius: 10px;
    width: 632px;
    max-width: 100%;

    .main_content {
        width: 100%;
    }
}

.root {
    padding: 44px;
    border-radius: 10px;
    width: 542px;
    max-width: 100%;

    .main_content {
        width: 100%;
    }
}

@media screen and (max-width: 680px) {

    .root,
    .end_step_root {
        padding: 30px;
    }
}