/* this file added by Bearer Web Developer  */
.root {
    margin: 16px;
    padding: 18px;
    border-radius: 10px;
    background: var(--secondary-2-hsb-82, #1D63D1);

    .header {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        .clear {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0;
            justify-content: end;
            min-width: unset;
            &::after {
                content: "";
                width: 0;
            }
            &:hover {
                background-color: unset;
                &::after {
                    content: "";
                    border-radius: 29px;
                    background: var(--neutral-hsb-93, #EDEDED);
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                }
            }
        }

    }

    .transports {
        margin-top: 25px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 13px;

        .transport {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px 10px 16px 10px;
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);

            .price {
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .time {
                margin-top: 8px;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .transport_default {
            cursor: pointer;
            &:hover {
                background: var(--secondary-2-hsb-45, #103673);
            }
        }
        .transport_selected {
            &:hover {
                background: var(--Primary, #009EFF);
            }
            background: var(--Primary, #009EFF);
        }

        .transport_disabled {
            opacity: 0.6;
        }
    }

    .confirm {
        margin-top: 25px;
        border-radius: 10px;
        background: var(--neutral-hsb-93, #EDEDED);
        padding: 12px 0px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        width: 100%;

        &:disabled {
            opacity: 0.5;
        }
        &:hover {
            background: var(--neutral-hsb-93, #EDEDED);
        }
    }
}

@media screen and (max-width: 834px) {

    .root {
        .transports {
            gap: 55px;
        }
    }
}

@media screen and (max-width: 680px) {

    .root {
        .transports {
            gap: 26px;
        }
    }
}

