/* this file added by Bearer Web Developer  */
.root {
    padding: 44px;
    border-radius: 10px;
    width: 542px;
    max-width: 100%;
    .main_content {
        width: 100%;
        header {

            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
                margin: 0;
                color: var(--Secondary-2, #14438E);
                /* Bold */
                font-family: GothamBold;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                /* 133.333% */
            }

        }

        p {
            margin-top: 7px;
            margin-bottom: 0px;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
        }
    }
}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }
}