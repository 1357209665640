/* this file added by Bearer Web Developer  */
.main_container {
    max-height: calc(100vh - 214px);
    height: 649px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 16px 16px 24px 16px;

    .incomplete_payment {
        border-radius: 10px;
        background: var(--secondary-2-hsb-82, #1D63D1);
        padding: 25px 21px;

        h6 {
            margin: 0;
            margin-top: 3px;
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 144.444% */
        }

        p {
            margin: 0;
            margin-top: 10px;
            margin-bottom: 17px;
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Book */
            font-family: GothamBook;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
        }
    }

    .button {
        width: 100%;
        border-radius: 10px;
        background: var(--Primary, #009EFF);
        margin-top: 24px;
        padding-top: 18px;
        padding-bottom: 18px;

        &:hover {
            background: var(--Primary, #009EFF);
        }

        span {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:disabled {
            opacity: 0.5;
        }
    }

}