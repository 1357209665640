/* this file added by Bearer Web Developer  */
.secret_generation {

    .copy_text_field_part {
        .copy_text_field {
            margin-top: 18px;
            margin-bottom: 18px;
        }
    }
    .add {
        margin-top: 18px;
        margin-bottom: 18px;
        display: flex;
        gap: 15px;
    }

}



@media screen and (max-width: 680px) {
    .secret_generation {
        .add {
            flex-direction: column;
        }
    
    }
}