/* this file added by Bearer Web Developer  */
.root {
    margin: 16px;
    padding: 18px;
    border-radius: 10px;
    background: var(--secondary-2-hsb-82, #1D63D1);

    .header {
        display: flex;
        justify-content: space-between;

        .transport {
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        .edit {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .transport_description {
        margin-top: 19px;
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                margin:0 0 0 12px;
                color: var(--neutral-hsb-96, #F5F5F5);
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
    
            h4 {
                margin:0 12px 0 12px;
                color: var(--Neutral, #FFF);
                text-align: right;
                /* Regular */
                font-family: GothamRegular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                /* 162.5% */
            }
            
            h5 {
                margin:0;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: right;
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

}