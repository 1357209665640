/* this file added by Bearer Web Developer  */
.information {
    padding: 14px;
    border-radius: 6px;
    background: var(--neutral-hsb-89, #E3E3E3);
    ul {
        padding: 0px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 43px;

        li {
            list-style-type: none;
            display: flex;
            align-items: center;
            gap: 9px;
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
        }
    }
}
