/* this file added by Bearer Web Developer  */
.root {
    padding: 44px;
    border-radius: 10px;
    width: 445px;
    max-width: 100%;

    .main {
        width: 100%;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;


            h2 {
                margin: 0;
                color: var(--Secondary-2, #14438E);
                /* Bold */
                font-family: GothamBold;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                /* 133.333% */
            }

            .cancel {
                padding: 0;
                justify-content: end;
                min-width: unset;

                &:hover {
                    svg path {
                        fill: #4D4D4D;
                    }
                }
            }
        }

        p {
            margin-top: 8px;
            margin-top: 26px;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;

            /* 162.5% */
            span {
                color: var(--Style, #16971B);
            }
        }

        .cover {
            border-radius: 10px;
            background: var(--secondary-hsb-70100, #4C91FF);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            position: relative;

            img {
                position: relative;
                top: 40px;
            }
        }

        .close_button {
            width: 100%;
            margin-top: 22.72px;
            border-radius: 10px;
            background: var(--Secondary, #14438E);
            padding: 12px;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
    }
}


@media screen and (max-width: 680px) {
    .root {
        padding: 30px;

    }
}