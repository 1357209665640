/* this file added by Bearer Web Developer  */
.root {
    padding: 44px;
    border-radius: 10px;
    width: 542px;
    max-width: 100%;

}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }
}