/* this file added by Bearer Web Developer  */
.root {
    margin: 16px;
    padding: 18px;
    border-radius: 10px;
    background: var(--secondary-2-hsb-82, #1D63D1);

    .header {

        display: flex;
        justify-content: space-between;

        .destination {
            display: flex;

            img {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }

            h2 {
                margin: 0px;
                color: var(--neutral-hsb-96, #F5F5F5);
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
        }

        .clear {
            border: 0;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0;
            justify-content: end;
            min-width: unset;

            &::after {
                content: "";
                width: 0;
            }

            &:hover {
                background-color: unset;

                &::after {
                    content: "";
                    border-radius: 29px;
                    background: var(--neutral-hsb-93, #EDEDED);
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                }
            }
        }

    }
    .destination_address_field {
        margin-top: 25px; 
    }
    .more_details_input {
        padding-top: 20px;
    }

    .extra_inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .phone_number {
            padding-top: 20px;
            margin-right: 10px;
        }
        .recipient_name {
            padding-top: 20px;
        }
    }

    .delivery {
        margin: 22px 0;
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .buttons {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .confirm_destination {
            background: var(--neutral-hsb-93, #EDEDED);
            padding: 12px 27px 12px 27px;
            border-radius: 10px;
            width: 100%;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */

            &:disabled {
                opacity: 0.5;
            }
        }
    }
}