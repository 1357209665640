/* this file added by Bearer Web Developer  */
.root {
    width: 100%;
    background: var(--neutral-hsb-93, #EDEDED);
    h6 {
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .receipt {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 13px;
            flex-shrink: 0;
            color: var(--secondary-2-hsb-66, #184FA8);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .email_input {
        padding-top: 20px;
    }
    .submit {
        width: 100%;
        margin-top: 20px;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);

        span {
            padding-top: 6px;
            padding-bottom: 6px;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:disabled {
            opacity: 0.5;
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }


}