/* this file added by Bearer Web Developer  */
.price {
    opacity: 0.5;
    width: 100%;
    padding: 15px;
    border-radius: 6px;
    background: var(--neutral-hsb-85, #D9D9D9);

    h3 {
        margin: 0px;
        color: var(--neutral-hsb-30, #4D4D4D);
        font-family: Gotham;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .data_number {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 2px;
    }
}