/* this file added by Bearer Web Developer  */
.root {
    margin: 16px;
    padding: 28px 21px;
    border-radius: 10px;
    background: var(--secondary-2-hsb-82, #1D63D1);
    p {
        margin: 0;
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Book */
        font-family: GothamBook;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
    }

    .confirm {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 14px;
        margin-top: 17px;

        .no {
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
            padding: 12px 8px;
            span {
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
        }

        .yes {
            border-radius: 10px;
            background: var(--Primary, #009EFF);
            padding: 12px 8px;
            span {
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
        }
    }
}