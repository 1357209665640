/* this file added by Bearer Web Developer  */
form.add_form {
    display: flex;
    padding: 8px;
    .add_submit {
        border-radius: 8px;
        background-color: var(--16971-b, #16971B);
        padding: 5px;
        min-width: unset;
        margin-left: 8px;
    }
    .add_submit_disabled {
        background: var(--neutral-hsb-74, #BDBDBD);
    }
}
.add_menu {
    display: flex;
    align-items: center;

    .add_button {
        padding: 5px;
        min-width: unset;
        border-radius: 8px;
        background: var(--neutral-hsb-74, #BDBDBD);
    }
}

