/* this file added by Bearer Web Developer  */
.paper { 
    background-color: #00000000 !important ;
    box-shadow: unset !important ;
}

.complete_profile_menu {
    max-width: 221px;
    cursor: pointer;
    background-color: white !important;
    color: var(--neutral-hsb-30, #4D4D4D);
    text-align: center;
    font-family: Gotham;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 122.4%; /* 14.688px */
    border-radius: 10px;
    background: var(--Neutral, #FFF);
    padding: 8px;
    /* App drop shadow */
    box-shadow: 0px 10px 7px -6px rgba(62, 62, 62, 0.14);
    .menu_item {
        span.text {
            padding: 18px 0;
            width: 100%;
            border-bottom: 2px solid rgba(189, 189, 189, 0.5);
            color: var(--Secondary-2, #14438E);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        span.text_end {
            padding: 18px 0;
            width: 100%;
            color: var(--Secondary-2, #14438E);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
