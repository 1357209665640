/* this file added by Bearer Web Developer  */
.root {
    /* scroll customization start*/
    /* width */
    ::-webkit-scrollbar {
        width: 8px;
        background: white;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 22px;
        background: var(--Secondary-2, #14438E);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--secondary-2-hsb-29, #0A234A);
    }
    /* scroll customization end*/

    .main {
        position: relative;
        overflow-y: hidden;
        width: 691px;
        max-width: 100%;
        padding: 44px;
        height: 902px;
    
        .header {
            display: flex;
            justify-content: space-between;
    
            h2 {
                margin: 0;
                color: var(--Secondary-2, #14438E);
                /* Bold */
                font-family: GothamBold;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                /* 133.333% */
            }
    
            .buttons {
                display: flex;
                align-items: center;
                gap: 12px;
    
                .refresh {
                    border-radius: 5px;
                    background: var(--neutral-hsb-85, #D9D9D9);
                    padding: 3px 5px;
    
                    img {
                        margin-right: 3px;
                    }
                }
    
                .cancel {
                    padding: 0;
                    justify-content: end;
                    min-width: unset;
    
                    &:hover {
                        svg path {
                            fill: #4D4D4D;
                        }
                    }
                }
            }
    
        }

    }
    
}


@media screen and (max-width: 680px) {
 
        .root {
            padding: 30px;
        }
    
}