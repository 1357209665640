/* this file added by Bearer Web Developer  */
.switch_integration {
    min-height: 52px;
    display: flex;
    align-items: center;

    .disable {
        padding: 12px;
        padding-right: 0px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Bold */
        font-family: GothamBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
        margin-right: 10px;
    }

    .enable {
        padding: 12px;
        padding-left: 0px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Bold */
        font-family: GothamBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
    }

    .switch {

        label {
            position: relative;
            display: inline-block;
            width: 76px;
            height: 42px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 76px;
                height: 42px;
                border-radius: 10px;
                background: var(--neutral-hsb-85, #D9D9D9);
                -webkit-transition: .1s;
                transition: .1s;
            }

            .slider:before {
                position: absolute;
                content: "";
                width: 30px;
                height: 30px;
                flex-shrink: 0;
                right: 6px;
                bottom: 6px;
                border-radius: 8px;
                background: var(--neutral-hsb-30, #4D4D4D);
                -webkit-transition: .1s;
                transition: .1s;
            }

            input:checked+.slider:before {
                -webkit-transform: translateX(-34px);
                -ms-transform: translateX(-34px);
                transform: translateX(-34px);
            }

        }
    }


}