/* this file added by Bearer Web Developer  */
.paper { 
    background-color: #00000000 !important ;
    box-shadow: unset !important ;
}
.arrow {
   
    display: flex;
    justify-content: center;
    svg {
        cursor: pointer;
    }
}
.accessing_feature {
    max-width: 221px;
    cursor: pointer;
    background-color: white !important;
    color: var(--neutral-hsb-30, #4D4D4D);
    text-align: center;
    font-family: Gotham;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 122.4%; /* 14.688px */
    border-radius: 10px;
    background: var(--Neutral, #FFF);
    padding: 8px;
    /* App drop shadow */
    box-shadow: 0px 10px 7px -6px rgba(62, 62, 62, 0.14);
}
