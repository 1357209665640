/* this file added by Bearer Web Developer  */
.delete_part {
    .delete_button {
        border-radius: 10px;
        outline: 2px solid var(--Red, #DF402A);
        min-width: unset;
        height: 50px;
        width: 50px;
    }
    .loading {
        height: unset;
        width: unset;
    }
}