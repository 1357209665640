/* this file added by Bearer Web Developer  */
.root {
    margin: 16px;
    padding: 18px;
    border-radius: 10px;
    background: var(--secondary-2-hsb-82, #1D63D1);

    .header {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            padding: 0;
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        .clear {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0;
            justify-content: end;
            min-width: unset;
            &::after {
                content: "";
                width: 0;
            }
            &:hover {
                background-color: unset;
                &::after {
                    content: "";
                    border-radius: 29px;
                    background: var(--neutral-hsb-93, #EDEDED);
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }

    .circular_progress {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }

    .list {
        margin: 13px 0;
    }

    .list_item {
        margin: 12px 0;
        padding: 0;
        display: flex;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        justify-content: space-between;
        &:hover {
            background: var(--secondary-2-hsb-45, #103673);
        }
        .type {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--neutral-hsb-96, #F5F5F5);
            flex-shrink: 0;
            /* Regular */
            font-family: GothamRegular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .image {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 12px;
                margin-right: 9px;
                img {
                    height: 100%;
                    max-height: 55px;
                }
            }
        }

        .description {
            width: 100%;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            font-family: Gotham;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            margin: 19.5px 14px;
            /* 141.667% */
        }
    }

    .confirm {
        border-radius: 10px;
        background: var(--neutral-hsb-93, #EDEDED);
        padding: 12px 0px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        width: 100%;

        &:disabled {
            opacity: 0.5;
        }
        &:hover {
            background: var(--neutral-hsb-93, #EDEDED);
        }
    }

}