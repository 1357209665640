/* this file added by Bearer Web Developer  */

.favourite_place {
    border-radius: 10px;
    background: var(--secondary-2-hsb-82, #1D63D1);
    margin: 10px 0;
    padding: 16.4px 0;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    justify-content: space-between;

    &:active {
        background: var(--Secondary-2, #14438E);
    }

    .favourite_place_info {
        display: flex;
        align-items: center;
        .location_favourite_place {
            margin-right: 14px;
            margin-left: 22px;
        }

        .list_item_text {
            h6 {
                margin: 0;
                margin-bottom: 12px;
                color: var(--Neutral, #FFF);
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                span {
                    color: var(--Neutral, #FFF);

                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            p {
                margin: 0;
                color: var(--Neutral, #FFF);

                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}