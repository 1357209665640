/* this file added by Bearer Web Developer  */

.tabs {
    .flex_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .tab_more {
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        background: var(--secondary-2-hsb-82, #1D63D1);
        // width: 157px; // for 354px max-width left side
        //width: 230px; // for 500px max-width left side
        width: 100%;
        min-width: 100%;
        padding: 18px 0 18px 0;
        border-radius: 10px 0px 0px 10px;
    }

    .tab_info {
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        background: var(--secondary-2-hsb-82, #1D63D1);
        // width: 157px; // for 354px max-width left side
        // width: 230px; // for 500px max-width left side
        width: 100%;
        min-width: 100%;
        padding: 18px 0 18px 0;
        border-radius: 0px 10px 10px 0px;
    }

}