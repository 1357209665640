/* this file added by Bearer Web Developer  */
.transport_rate {
    margin: 16px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

        h3 {
            margin: 0;
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            /* 114.286% */
        }

        .skip {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .rider_info {

        display: flex;
        gap: 20px;
        justify-items: center;

        .image {
            border-radius: 10px;
            background: var(--secondary-2-hsb-66, #184FA8);
            width: 100%;
            // max-width: 123px; // for 354px max-width left side
            max-width: 190px; // for 500px max-width left side

            .image_box {
                margin-left: 11px;
                margin-right: 11px;
                margin-top: 18px;
                margin-bottom: 14px;
                display: flex;
                justify-content: center;

            }

            p {
                margin-left: 11px;
                margin-right: 11px;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .type_riding {
            border-radius: 10px;
            background: var(--secondary-2-hsb-66, #184FA8);
            width: 100%;

            p {
                margin-top: 16px;
                margin-bottom: 12px;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .motorbike_registration {
                margin: 12px 22px 15px 22px;
                border-radius: 10px;
                border: 2px solid var(--Primary-2, #FF8A29);

                h5 {
                    margin-top: 6px;
                    margin-bottom: 1px;
                    color: var(--neutral-hsb-96, #F5F5F5);
                    text-align: center;
                    font-family: Gotham;
                    font-size: 24.727px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40.182px;
                    /* 162.5% */
                }

                h6 {
                    margin-top: 1px;
                    margin-bottom: 11px;
                    color: var(--neutral-hsb-96, #F5F5F5);
                    text-align: center;
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

            }
        }

        .not_rider {
            width: 100%;
            border-radius: 10px;
            background: var(--secondary-2-hsb-66, #184FA8);
            padding: 23px 17px 28px 17px;
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;

            h5 {
                margin: 0;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: right;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                /* 143.75% */
            }
        }
    }

    .rating {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 18px;
        border-radius: 10px;
        background: var(--secondary-2-hsb-66, #184FA8);

        .rate {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;

            /* 114.286% */
            .star {
                color: #FFAA64
            }
        }
    }

    .time_line {
        border-radius: 10px;
        background: var(--secondary-2-hsb-66, #184FA8);

        padding: 16px;
        margin-top: 20px;
        margin-bottom: 20px;

        .content {
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .timelineItem_root {
            min-height: 10px;
        }

        .missing_opposite_content::before {
            flex: 0;
            padding: 0;
        }
    }

    .submit {
        width: 100%;
        border-radius: 10px;
        background: var(--Primary, #009EFF);
        padding: 18px;
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        &:disabled {
            background: var(--secondary-2-hsb-29, #0A234A);
            color: var(--neutral-hsb-96, #F5F5F5);
            opacity: 0.5;
        }
    }
}