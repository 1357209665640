/* this file added by Bearer Web Developer  */
.sort_menu {
    display: flex;
    align-items: center;
    align-items: center;
    .filter {
        border-radius: 5px;
        background: var(--neutral-hsb-85, #D9D9D9);
        padding: 3px 5px;

        img {
            margin-right: 3px;
        }
    }
}

