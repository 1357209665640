/* this file added by Bearer Web Developer  */
.business_logo {

    margin-top: 26px;
    margin-bottom: 26px;

    h4 {
        margin-bottom: 10px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .business {
        display: flex;
        align-items: center;

        .none_avatar {
            margin-right: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 31px;
            background: var(--neutral-hsb-89, #E3E3E3);
            border-radius: 50%;
        }
        .avatar {
            margin-right: 22px;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 118px;
            height: 118px;
            border-radius: 50%;
            overflow: hidden;
        }
        .loading {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 118px;
            height: 118px;
        }
        label.edit {
            cursor: pointer;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            border-radius: 5px;
            background: var(--neutral-hsb-89, #E3E3E3);
            input {
                display: none;
            }
        }

        h5 {
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
    }
}