/* this file added by Bearer Web Developer  */
.root {
    padding: 44px;
    width: 468px;
    max-width: 100%;

    .header {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }
        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;
            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }
    }

    .avatar_part {
        margin-top: 34px;
        display: flex;
        justify-content: center;

        .avatar_content {
            margin-right: 22px;
        }

        h5 {
            margin: 25px 0 0 0;
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }




        .edit {
            margin-top: 15px;
        }

    }

    .name {
        margin-top: 28px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        .first_name_input,.last_name_input {
            padding-top: 20px;
        }
    }
    .phone_number_input,.email_input {
        padding-top: 20px;
    }

    .save_changes {
        margin-top: 22px;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;

        span {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;

            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }

    .more {
        margin-top: 33px;
        display: flex;
        justify-content: center;

        .logout {
            margin-right: 19px;
            color: var(--Secondary-2, #14438E);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }
}