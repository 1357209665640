/* this file added by Bearer Web Developer  */
.disabled {
    opacity: 0.5;
}
.order_data {
    margin-top: 15px;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 15px;
}

@media screen and (max-width: 680px) {
    .order_data {
        display: flex;
        flex-direction: column;

    }
}