/* this file added by Bearer Web Developer  */
.content {
    position: relative;
    p.document {
        margin-top: 18px;
        margin-bottom: 18px;
        color: var(--neutral-hsb-15, #262626);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        a {
            color: var(--Secondary-2, #14438E);
        }
    }

    .generate_api_secret {
        position: sticky;
        bottom: 0;
        padding-bottom: 44px;
        background-color:  var(--neutral-hsb-96, #fff);
        // box-shadow: 0px -20px 20px 1px #fff;
        .generate_api_secret_button {
            width: 100%;
            padding: 12px;
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: right;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
        .regenerate {
            background: var(--secondary-2-hsb-82, #1D63D1);
        }
    }
}