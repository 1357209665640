/* this file added by Bearer Web Developer  */
.paper {
    width: 600px;
    border-radius: 10px;
    background-color: #87b5ff;
}

.check_devices_popup {
    padding: 44px;

    p {
        margin: 0px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    button {
        margin-top: 20px;
        padding: 12px;
        width: 100%;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }
}

@media screen and (max-width: 680px) {
    .check_devices_popup {
        padding: 28px;
    }
}