/* this file added by Bearer Web Developer  */
.second_part {
    width: 100%;
    max-width: 114px;

    .second {
        border-radius: 10px;
        background: var(--primary-3-hsb-70100, #4C91FF);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        padding: 11px;
        width: 100%;
    }
}