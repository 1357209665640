/* this file added by Bearer Web Developer  */
.reservation_source {
    width: 100%;
    padding: 14px;
    border-radius: 6px;
    background: var(--neutral-hsb-89, #E3E3E3);
    display: flex;
    align-items: center;
    gap: 9px;


    /* 128.571% */
    h4 {
        margin: 0px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    p {
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-decoration-line: underline;
        word-break: break-all;
    }

}