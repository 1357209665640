/* this file added by Bearer Web Developer  */
.main_drawer_menu {
    display: none;
}

.root {
    border-radius: 0px 0px 20px 0px;
    background: var(--neutral-hsb-93, #EDEDED);
    box-shadow: 0px 3px 12px 10px rgba(84, 84, 84, 0.08);
    display: flex;
    justify-content: space-between;
    height: 87px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login {
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        margin-right: 38px;
        padding: 11px 16px 10px 16px;
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;

        /* 162.5% */
        &:hover {
            background: var(--Secondary-2, #0a234c);
        }

        &:active {
            outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }
}

@media screen and (max-width: 834px) {
    .main_drawer_menu {
        display: block;
    }

    .drawer_menu {
        margin-top: 54px;
        border-radius: 0px 10px 0px 0px;
        background: var(--Neutral, #FFF);
        height: calc(100% - 54px);
        display: flex;
        flex-direction: column;
        max-width: 290px;
        overflow: auto;
        .drawer_menu_items {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
        }
    }

    .root {
        display: none;
    }
}