/* this file added by Bearer Web Developer  */
.root {

    width: 693px;
    max-width: 100%;
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 31px;

        h4 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

    }

    .order_part {
        padding: 26px;
        border-radius: 10px;
        background: var(--neutral-hsb-93, #EDEDED);

        .order_header {
            opacity: 0.5;
            display: flex;
            align-items: center;

            img {
                margin-right: 10px;
            }

            span {
                color: var(--neutral-hsb-52, #858585);
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .made_of_transport_part {
            margin-top: 15px;
            display: flex;
            gap: 15px;
        }
    }
    .update {
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        min-width: unset;
        padding: 12px;
        width: 100%;
        &:hover {
            background: var(--Secondary-2, #14438E);
            color: var(--neutral-hsb-96, #F5F5F5);
            transition: none;
        }
    }
}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;

        .order_part {
            .made_of_transport_part {
                flex-direction: column;
            }
        }
    }
}