/* this file added by Bearer Web Developer  */


.tooltip {
    background-color: white !important;
    color: var(--neutral-hsb-30, #4D4D4D) !important;
    /* Bold */
    font-family: GothamBold !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    border-radius: 10px !important; 
}

.arrow {
    color: white !important;
}

.business_number_input {
    position: relative;

    .label {
        margin-bottom: 9px;
        display: flex;
        align-items: center;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
            margin-left: 4px;
            color: var(--neutral-hsb-30, #4D4D4D);
            font-family: Gotham;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }


        img {
            cursor: pointer;
            margin-left: 4px;
        }
    }

    .numbers {

        .loading {
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .error {
            color: red;
            margin-top: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            img {
                margin-right: 6px;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
            }
        }

        .confirm {
            text-align: center;
            position: relative;
            background-image: url('./../../../../../assets/images/check-circle-green.webp');
            background-repeat: no-repeat;
            background-position: 100% 100%;
            background-size: 109px 102px;
            border-radius: 0px 0px 10px 10px;

            h4 {
                margin: 0px;
                padding-top: 27px;
                padding-bottom: 5px;
                color: var(--neutral-hsb-30, #4D4D4D);
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }

            .clear {
                margin-bottom: 27px;
                border-radius: 4px;
                background: var(--neutral-hsb-89, #E3E3E3);
                text-align: center;

                span {
                    color: var(--neutral-hsb-52, #858585);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                &:hover,
                &:active {
                    background: var(--neutral-hsb-89, #E3E3E3);
                }
            }
        }

    }

    .numbers_confirm {
        border-radius: 10px;
        background: var(--neutral-hsb-92, #EAEAEA);
    }
}