/* this file added by Bearer Web Developer  */
.root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    border-radius: 10px;
    background: var(--neutral-hsb-96, #F5F5F5);

    .price {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2,
    h3,
    h4,
    h5 {
        margin: 0;
    }

    h2 {

        color: var(--secondary-2-hsb-66, #184FA8);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    h4 {
        color: var(--Secondary-2, #14438E);
        text-align: right;
        /* Regular */
        font-family: GothamRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
    }

    .remove_button {

        margin-left: 22px;
        padding: 0;

        &:hover {
            background: unset;
        }

        span.text {
            border-radius: 10px;
            background: var(--neutral-hsb-89, #E3E3E3);

            padding: 15px 8px;
            color: var(--Style, #DF402A);
            text-align: center;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

        }
    }
    .discounted {
        margin-left: 22px;
        padding: 0;
        span.text {
            border-radius: 10px;
            background: var(--neutral-hsb-89, #E3E3E3);
            padding: 15px 8px;
            color: var(--Style, #DF402A);
            text-align: center;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .real_price {
        margin-left: 10px;
        color: var(--neutral-hsb-52, #858585);
        text-align: right;
        /* Light */
        font-family: GothamLight;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration-line: line-through;
    }

    .discounted_price {
        margin-left: 10px;
        color: var(--16971-b, #16971B);
        text-align: right;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }
    .free {
        color: var(--16971-b, #16971B);
        text-align: right;
        /* Bold */
        font-family: GothamBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }


}