/* this file added by Bearer Web Developer  */
.root {
    width: 468px;
    padding: 44px;
}
.search_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image_part {
        padding-top: 133px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .image_box {
            overflow: hidden;
            height: 335px;
            width: 193px;
            height: 193px;
            flex-shrink: 0;
            border-radius: 20px;
    
            img {
                width: 100%;
                bottom: 286px;
                position: relative;
                top: -78px;
            }
        }
    }
    .searching_text {
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        margin-top: 20px;
        padding-bottom: 60px;
        /* Bold */
        font-family: Gotham;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }
}


.cancel_box {
    margin-top: 0px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;

    .cancel {
        border-radius: 10px;
        background: var(--secondary-2-hsb-82, #1D63D1);
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;

        span {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:hover {
            background: var(--Secondary-2, #0a234c);
        }

        &:active {
            outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }
}

.rider_not_found {
    p {
        margin: 0;
        margin-bottom: 18px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .confirm {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 28px;
        justify-content: stretch;

        .no {
            border-radius: 10px;
            border: 2px solid var(--Secondary-2, #14438E);
            width: 100%;
            padding: 10.4px 8px;
            color: var(--Secondary-2, #14438E);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;

            /* 162.5% */
            &:hover {
                span {
                    color: var(--neutral-hsb-96, #F5F5F5);
                }

                background: var(--secondary-2-hsb-29, #0A234A);
            }

            &:active {
                span {
                    color: var(--neutral-hsb-96, #F5F5F5);
                }

                outline: 4px solid var(--secondary-2-hsb-66, #184FA8);
                background: var(--secondary-2-hsb-29, #0A234A);
            }
        }

        .yes {
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
            width: 100%;
            padding: 10.4px 8px;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;

            /* 162.5% */
            &:hover {
                background: var(--secondary-2-hsb-29, #0A234A);
            }

            &:active {
                outline: 4px solid var(--secondary-2-hsb-66, #184FA8);
                background: var(--secondary-2-hsb-29, #0A234A);
            }

        }
    }
}

.rider_not_found_after_two_search {
    h3 {
        margin: 0;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 133.333% */
    }

    p {
        margin: 0;
        margin-top: 8px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .button {
        width: 100%;
        margin-top: 19px;
        padding-top: 12px;
        padding-bottom: 12px;
        color: var(--Secondary-2, #14438E);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        border-radius: 10px;
        border: 2px solid var(--Secondary-2, #14438E);
    }
}


@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }

    .cancel_box {
        margin-bottom: 90px;
    }    
}