/* this file added by Bearer Web Developer  */

.favourite_places {
    cursor: pointer;
    border-radius: 10px;
    outline: 2px solid var(--secondary-2-hsb-82, #1D63D1);
    background: var(--neutral-hsb-89, #E3E3E3);
    margin: 10px 0;
    padding: 16.4px 0;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s;
    &:active {
        background: var(--neutral-hsb-74, #BDBDBD);
    }
    .favourite_place_info {
        display: flex;
        align-items: center;
        .location_favourite_place {
            margin-right: 14px;
            margin-left: 22px;
        }
    
        .list_item_text {
            h6 {
                margin: 0;
                margin-bottom: 12px;
                color: var(--secondary-2-hsb-82, #1D63D1);
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
    
            p {
                margin: 0;
                color: var(--secondary-2-hsb-82, #1D63D1);
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}