/* this file added by Bearer Web Developer  */
.root {
    padding: 44px;
    border-radius: 10px;
    background: var(--neutral-hsb-96, #F5F5F5);

    p {
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        margin: 0;
        padding: 0;
        margin-bottom: 18px;
    }
    .buttons {
        display: flex;
        gap: 10px;
        .add {
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
            width: 100%;
            padding: 12px 8px;
    
            span {
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
            &:hover {
                background: var(--Secondary-2, #0a234c);
            }
        }
        .cancel {
            border-radius: 10px;
            background: var(--neutral-hsb-96, #F5F5F5);
            width: 100%;
            padding: 12px 8px;
    
            span {
                color: var(--Secondary-2, #14438E);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
          
        }
    }

}