/* this file added by Bearer Web Developer  */
.text_input {
    width: 100%;

    .header {
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {

            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .forgot_password {
            a {
                color: var(--Secondary-2, #14438E);
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration: none;
            }
        }

    }

    input {
        border: none;
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Light */
        font-family: GothamLight;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-radius: 10px;

        height: 48px;
        padding: 19px 37px 19px 14px;
        width: 100%;
    }

    .error {
        color: red;
        padding: 5px;
    }

    .input {
        position: relative;

        input {
            padding-right: 58px;
        }

        .visibility {
            position: absolute;
            right: 0;
            top: 0;
            padding: 10px 15px 10px 15px;
        }
    }

    .blue {
        .label {
            color: var(--neutral-hsb-96, #F5F5F5);
        }

        input {
            background: var(--secondary-2-hsb-70100, #4E92FF);
        }
    }

    .gray {
        .label {
            color: var(--neutral-hsb-30, #4D4D4D);
        }

        input {
            background: rgba(189, 189, 189, 0.5);
            color: var(--neutral-hsb-15, #262626);

            &::placeholder {
                /* Most modern browsers support this now. */
                color: var(--neutral-hsb-15, #262626);
            }
        }
    }
}