/* this file added by Bearer Web Developer  */
.stripe_payment {
    .card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 22px;
        margin-bottom: 22px;

        img {
            margin-left: 16px;
        }

        .card_element_box {
            width: 100%;
            margin-left: 20px;
            margin-right: 18px;

           // .card_element {}
        }

        .add {
            flex-shrink: 0;
            color: var(--Neutral, #FFF);
            text-align: right;
            /* Regular */
            font-family: GothamRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            /* 162.5% */
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
            &:hover {
                background: var(--Secondary-2, #0a234c);
            }
            &:active {
                outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
                background: var(--secondary-2-hsb-29, #0A234A);
            }
        }

    }
}