/* this file added by Bearer Web Developer  */
.disabled {
    opacity: 0.7;
}

.text_input {
    width: 100%;

    .label {
        padding-bottom: 8px;
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    textarea {
        border: none;
        border-radius: 10px;
        width: 100%;
        resize: none;
    }

    .error {
        color: red;
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        img {
            margin-right: 6px;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
        }

        .error_blue {
            color: var(--neutral-hsb-89, #E3E3E3);
            /* Book */
            font-family: Gotham;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 117.233%;
            /* 11.723px */
        }
    }

    .blue {
        .label {
            color: var(--neutral-hsb-96, #F5F5F5);
        }

        textarea {
            background: var(--secondary-2-hsb-70100, #4E92FF);
            color: var(--neutral-hsb-96, #F5F5F5);

            &::placeholder {
                color: var(--neutral-hsb-96, #F5F5F5);
            }

            /* Light */
            font-family: GothamLight;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            outline: none;

            &:focus {
                border: 2px solid var(--neutral-hsb-96, #F5F5F5);
            }
        }
    }

    .gray {
        .label {
            color: var(--neutral-hsb-30, #4D4D4D);
        }

        textarea {
            background: rgba(189, 189, 189, 0.5);
            color: var(--neutral-hsb-15, #262626);
            &::placeholder {
                color: var(--neutral-hsb-15, #262626);
            }

            /* Light */
            font-family: GothamLight;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }

    .white {

        .label {
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Light */
            font-family: Gotham;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        textarea {
            background: var(--neutral-hsb-89, #E3E3E3);
            color: var(--neutral-hsb-15, #262626);
            &::placeholder {
                color: var(--neutral-hsb-15, #262626);
                /* Light */
                font-family: GothamLight;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            /* Light */
            font-family: GothamLight;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }

}