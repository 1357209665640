/* this file added by Bearer Web Developer  */

.complete_profile_button_part {
    margin-right: 30px;
    .muibtn_root,
    .muibtn_text {
        z-index: 1301;
        padding: 0px 0px;
        border-radius: 10px;
    }
    .complete_profile_content {
        
        .complete_profile {
            transition: all 0.7s;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            background-color: var(--neutral-hsb-89, #E3E3E3);
    
            .persent_step_one {
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 37px;
                min-width: 37px;
                height: 37px;
                min-height: 37px;
                padding: 2px;
                border-radius: 50%;
                background-color: var(--Secondary-2, #14438E);
                background-image: linear-gradient(216deg, transparent 50%, #E3E3E3 50%), linear-gradient(90deg, #E3E3E3 50%, transparent 50%);
    
                .circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    min-width: 35px;
                    height: 35px;
                    min-height: 35px;
                    border-radius: 50%;
                    background-color: #ffffff;
    
                    .persent_inner {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 35px;
                        min-width: 35px;
                        height: 35px;
                        min-height: 35px;
                        padding: 2px;
                        border-radius: 50%;
                        background-color: var(--Secondary-2, #14438E);
                        background-image: linear-gradient(216deg, transparent 50%, #ffffff 50%), linear-gradient(90deg, #ffffff 50%, transparent 50%);
    
                        .circle_inner {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 33px;
                            min-width: 33px;
                            height: 33px;
                            min-height: 33px;
                            border-radius: 50%;
                            background-color: #ffffff;
                            color: var(--Secondary-2, #14438E);
                            text-align: center;
                            font-family: GothamBold;
                            font-style: italic;
                            font-size: 14px;
                            font-style: italic;
                            font-weight: 500;
                            line-height: 11px;
                            /* 78.571% */
                        }
                    }
                }
    
            }
    
            .persent_step_two {
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 37px;
                min-width: 37px;
                height: 37px;
                min-height: 37px;
                padding: 2px;
                border-radius: 50%;
                background-color: var(--Secondary-2, #14438E);
                background-image: linear-gradient(270deg, transparent 50%, #E3E3E3 50%), linear-gradient(90deg, #E3E3E3 50%, transparent 50%);
    
                .circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    min-width: 35px;
                    height: 35px;
                    min-height: 35px;
                    border-radius: 50%;
                    background-color: #ffffff;
    
                    .persent_inner {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 35px;
                        min-width: 35px;
                        height: 35px;
                        min-height: 35px;
                        padding: 2px;
                        border-radius: 50%;
                        background-color: var(--Secondary-2, #14438E);
                        background-image: linear-gradient(270deg, transparent 50%, #ffffff 50%), linear-gradient(90deg, #ffffff 50%, transparent 50%);
    
                        .circle_inner {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 33px;
                            min-width: 33px;
                            height: 33px;
                            min-height: 33px;
                            border-radius: 50%;
                            background-color: #ffffff;
                            color: var(--Secondary-2, #14438E);
                            text-align: center;
                            font-family: GothamBold;
                            font-style: italic;
                            font-size: 14px;
                            font-style: italic;
                            font-weight: 500;
                            line-height: 11px;
                            /* 78.571% */
                        }
                    }
                }
    
            }
    
            .text {
                text-align: left;
                h5 {
                    margin-right: 10px;
                    color: var(--Secondary-2, #14438E);
                    margin-top: 0px;
                    margin-bottom: 4px;
                    /* Bold */
                    font-family: GothamBold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px;
                    /* 162.5% */
                }
    
                h6 {
                    margin-right: 10px;
                    margin-top: 4px;
                    margin-bottom: 0px;
                    color: var(--Secondary-2, #14438E);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
    
            .expand {
                width: 13px;
                height: 18px;
                background-image: url('./../../../assets/images/expand_more_right.png');
            }
    
            &:hover {
                border-radius: 10px;
                background: var(--Secondary-2, #14438E);
    
                .text {
    
                    h5,
                    h6 {
                        margin-right: 4px;
                        color: var(--neutral-hsb-93, #EDEDED);
                    }
                }
    
                .expand {
                    width: 18px;
                    height: 13px;
                    background-image: url('./../../../assets/images/expand-address.png');
                }
    
                .persent_step_one {
                    background-color: var(--neutral-hsb-93, #EDEDED);
                    background-image: linear-gradient(216deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
                    color: var(--neutral-hsb-93, #EDEDED);
    
                    .circle {
                        background: var(--Secondary-2, #14438E);
    
                        .persent_inner {
                            background-color: var(--neutral-hsb-93, #EDEDED);
                            background-image: linear-gradient(216deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
    
                            .circle_inner {
                                background-color: var(--secondary-2-hsb-70100, #4E92FF);
                                color: var(--neutral-hsb-93, #EDEDED);
                            }
                        }
                    }
                }
    
                .persent_step_two {
                    background-color: var(--neutral-hsb-93, #EDEDED);
                    background-image: linear-gradient(270deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
                    color: var(--neutral-hsb-93, #EDEDED);
    
                    .circle {
                        background: var(--Secondary-2, #14438E);
    
                        .persent_inner {
                            background-color: var(--neutral-hsb-93, #EDEDED);
                            background-image: linear-gradient(270deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
    
                            .circle_inner {
                                background-color: var(--secondary-2-hsb-70100, #4E92FF);
                                color: var(--neutral-hsb-93, #EDEDED);
                            }
                        }
                    }
                }
            }
    
        }
    
        .active {
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
    
            .text {
    
                h5,
                h6 {
                    margin-right: 4px;
                    color: var(--neutral-hsb-93, #EDEDED);
                }
            }
    
            .expand {
                width: 18px;
                height: 13px;
                background-image: url('./../../../assets/images/expand-address.png');
            }
    
            .persent_step_one {
                background-color: var(--neutral-hsb-93, #EDEDED);
                background-image: linear-gradient(216deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
                color: var(--neutral-hsb-93, #EDEDED);
    
                .circle {
                    background: var(--Secondary-2, #14438E);
    
                    .persent_inner {
                        background-color: var(--neutral-hsb-93, #EDEDED);
                        background-image: linear-gradient(216deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
    
                        .circle_inner {
                            background-color: var(--secondary-2-hsb-70100, #4E92FF);
                            color: var(--neutral-hsb-93, #EDEDED);
                        }
                    }
                }
            }
    
            .persent_step_two {
                background-color: var(--neutral-hsb-93, #EDEDED);
                background-image: linear-gradient(270deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
                color: var(--neutral-hsb-93, #EDEDED);
    
                .circle {
                    background: var(--Secondary-2, #14438E);
    
                    .persent_inner {
                        background-color: var(--neutral-hsb-93, #EDEDED);
                        background-image: linear-gradient(270deg, transparent 50%, #14438E 50%), linear-gradient(90deg, #14438E 50%, transparent 50%);
    
                        .circle_inner {
                            background-color: var(--secondary-2-hsb-70100, #4E92FF);
                            color: var(--neutral-hsb-93, #EDEDED);
                        }
                    }
                }
            }
        }
        .hamburger_menu {
            display: none;
            margin-left: 16px;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 11px;
                border-radius: 10px;
                background: var(--Neutral, #FFF);
                box-shadow: 0px 38px 31px -27px rgba(47, 47, 47, 0.37);
                cursor: pointer;
                color: white;
            }
        }
  
     }

}

@media screen and (max-width: 834px) {
    .complete_profile_button_part {
        .complete_profile_content {
            .complete_profile {
                display: none;
            }
            .hamburger_menu {
                display: flex;
                justify-content: start;
                align-items: center;
            }
        }
    }
}