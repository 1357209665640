/* this file added by Bearer Web Developer  */
.autocomplete_input {
    position: relative;

    input {
        border: none;
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Light */
        font-family: GothamLight;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-radius: 10px;
        background: var(--secondary-2-hsb-70100, #4E92FF);
        height: 48px;
        padding: 19px 37px 19px 14px;
        width: 100%;
    }

    img {
        position: absolute;
        right: 0;
        padding: 14px 14px 12px 12px;
    }

}

.white {
    input {
        background: var(--neutral-hsb-89, #E3E3E3);
        color: var(--neutral-hsb-15, #262626);

        &::placeholder {
            color: var(--neutral-hsb-15, #262626);
            /* Light */
            font-family: GothamLight;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        /* Light */
        font-family: GothamLight;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

.blue {
    input {
        background: var(--secondary-2-hsb-70100, #4E92FF);
        color: var(--neutral-hsb-96, #F5F5F5);

        &::placeholder {
            /* Most modern browsers support this now. */
            color: var(--neutral-hsb-96, #F5F5F5);
        }

        /* Light */
        font-family: GothamLight;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        outline: none;

        &:focus {
            border: 2px solid var(--neutral-hsb-96, #F5F5F5);
        }

    }
}

.gray {
    input {
        background: rgba(189, 189, 189, 0.5);
        color: var(--neutral-hsb-15, #262626);

        &::placeholder {
            /* Most modern browsers support this now. */
            color: var(--neutral-hsb-15, #262626);
        }

        /* Light */
        font-family: GothamLight;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

    }
}

.icon {
    margin: 5px;
}

.label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    color: var(--neutral-hsb-96, #F5F5F5);
    /* Regular */
    font-family: GothamRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .favourite_places {
        padding: 0;
        justify-content: start;
        min-width: unset;
        border-radius: 6px;
        background: #357AE8;
        border-radius: 6px;
        padding: 4px 5px;

        &:hover {
            background: #357AE8;
        }
        .image {
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            margin-right: 5px;
        }

        .text {
            color: var(--neutral-hsb-96, #F5F5F5);
            font-family: Gotham;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .blue {
        color: var(--neutral-hsb-96, #F5F5F5);
    }

    .gray {
        color: var(--neutral-hsb-30, #4D4D4D);
    }

    .white {
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Light */
        font-family: Gotham;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

}

.items_list_group {
    background: var(--secondary-2-hsb-70100, #4E92FF);
    color: var(--neutral-hsb-96, #F5F5F5);
    padding: 0;
    margin: 0;
    /* Light */
    font-family: GothamLight;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.end_adornment {
    svg {
        display: none;
    }
}

.popup_indicator_blue {
    background: url('./../../../assets/images/expand-address.png') no-repeat center center;
    right: 10px;
    top: -6px;
    width: 40px;
    height: 40px;
}

.popup_indicator_white {
    background: url('./../../../assets/images/expand_more_black.png') no-repeat center center;
    right: 10px;
    top: -6px;
    width: 40px;
    height: 40px;
}

.items_list {
    margin: 0;
    padding: 0;
    width: 100%;
    // background: var(--secondary-2-hsb-70100, #4E92FF);
    display: flex;
    align-items: center;
    color: var(--neutral-hsb-96, #F5F5F5);
    /* Light */
    font-family: GothamLight;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    img {
        margin-right: 6px;
    }

    p {
        margin: 0;

    }

}