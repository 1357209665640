/* this file added by Bearer Web Developer  */
.root {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;

        .avatar {
            width: 96px;
            height: 96px;
            padding: 20px;
        }
        .avatar_person {
            width: 96px;
            height: 96px;
        }
        .input {
            display: none;
        }
    }
}