/* this file added by Bearer Web Developer  */
.more_datails {
    display: flex;
    align-items: center;

    span {
        color: var(--Secondary-2, #14438E);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}