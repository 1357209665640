/* this file added by Bearer Web Developer  */
.business_name {
    display: flex;
    align-items: center;

    .splitter {
        width: 6px;
        height: 37px;
        flex-shrink: 0;
        border-radius: 75px;
        background: var(--neutral-hsb-74, #BDBDBD);
        margin-right: 16px;
    }

    h3 {
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }
}


@media screen and (max-width: 834px) {
    .business_name {
        order: 1;
    }
}