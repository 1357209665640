/* this file added by Bearer Web Developer  */
.edit_part {
    .edit {
        width: 100%;
        max-width: 114px;
        border-radius: 10px;
        background: var(--primary-38666, #184FA8);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: Gotham;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        padding: 11px;

        &:hover {
            color: var(--neutral-hsb-96, #F5F5F5);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
        &:active {
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }
}