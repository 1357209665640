/* this file added by Bearer Web Developer  */
.expire_time {
    opacity: 0.5;
    width: 100%;
    padding: 15px;
    border-radius: 6px;
    background: var(--neutral-hsb-85, #D9D9D9);

    h4 {
        margin-top: 0px;
        margin-bottom: 5px;
        color: var(--neutral-hsb-30, #4D4D4D);
        font-family: Gotham;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

    }
    span {
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}