/* this file added by Bearer Web Developer  */
.management {
    .management_button {
        span {
            color: var(--Secondary-2, #14438E);
            text-align: right;
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        svg {
            margin-left: 4px;
        }
    }
}



@media screen and (max-width: 680px) {
    .management {
        margin-top: 10px;
    }
}