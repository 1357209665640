/* this file added by Bearer Web Developer  */
.paper {
    border-radius: 10px;
}

.root {

    /* scroll customization start*/
    /* width */
    ::-webkit-scrollbar {
        width: 8px;
        background: white;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 22px;
        background: var(--Secondary-2, #14438E);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--secondary-2-hsb-29, #0A234A);
    }

    /* scroll customization end */

    .main {
        width: 691px;
        max-width: 100%;
        padding: 44px;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;


            h2 {
                margin: 0;
                color: var(--Secondary-2, #14438E);
                /* Bold */
                font-family: GothamBold;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                /* 133.333% */
            }

            .cancel {
                padding: 0;
                justify-content: end;
                min-width: unset;

                &:hover {
                    svg path {
                        fill: #4D4D4D;
                    }
                }
            }
        }

        .transports_history {
            margin-top: 31px;

            .loading {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
            }

            .load_more_items_block {
                display: flex;
                justify-content: center;

                .load_more_items {
                    margin-top: 12px;

                    color: var(--Secondary-2, #14438E);
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &:disabled {
                        opacity: 0.5;
                    }
                }
            }


            .MuiAccordionSummary_content {
                display: grid;
                grid-template-columns: 1fr;

            }

            .MuiAccordionSummary_root {
                display: grid;
                grid-template-columns: 1fr;
            }

            .accordion {
                box-shadow: none;
                margin-bottom: 32px;
                padding: 24px 26px;
                border-radius: 10px;
                background: var(--neutral-hsb-93, #EDEDED);

                .transport_main {
                    width: 100%;
                    margin-top: 12px;
                    display: grid;
                    grid-template-columns: 1fr;

                    .transport {
                        display: flex;
                        justify-content: space-between;

                        .transport_info {
                            display: flex;

                            p.price {
                                margin-top: 18px;
                                margin-bottom: 0px;
                                color: var(--neutral-hsb-30, #4D4D4D);
                                font-family: Gotham;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                            }

                            p.price_main {
                                margin-top: 7px;
                                margin-bottom: 10px;
                                color: var(--neutral-hsb-66, #A8A8A8);
                                /* Bold */
                                font-family: GothamBold;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                text-decoration: line-through;
                            }

                            p.price_discounted {
                                margin-top: 10px;
                                margin-bottom: 12px;
                                color: var(--neutral-hsb-30, #4D4D4D);
                                font-family: Gotham;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;

                                span.discounted {
                                    margin-left: 8px;
                                    padding: 8px;
                                    border-radius: 5px;
                                    background: var(--neutral-hsb-85, #D9D9D9);
                                    color: var(--Red, #DF402A);
                                    /* Regular */
                                    font-family: GothamRegular;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }

                            }

                            p.free {
                                color: var(--16971-b, #16971B);
                                /* Bold */
                                font-family: GothamBold;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                            }

                            p.cancel_color {
                                margin-top: 8px;
                                margin-bottom: 0px;
                                color: var(--Style, #DF402A);
                                /* Regular */
                                font-family: GothamRegular;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }

                            p.receive_color {
                                margin-top: 8px;
                                margin-bottom: 0px;
                                color: var(--16971-b, #16971B);
                                /* Regular */
                                font-family: GothamRegular;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }


                            .transport_image {
                                margin-right: 16px;
                                border-radius: 10px;
                                background: var(--Secondary-2, #14438E);
                                padding: 12px 17px;
                            }
                        }

                        .time {
                            margin-top: 4px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 8px;

                                span {
                                    color: var(--neutral-hsb-52, #858585);
                                    /* Regular */
                                    font-family: GothamRegular;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }

                                img {
                                    margin-right: 8px;
                                }
                            }
                        }
                    }

                    .more_data {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        p {
                            flex-shrink: 0;
                            margin: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                margin-right: 10px;
                            }
                        }

                        .more {
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;

                            img {
                                padding-right: 4px;
                            }

                            span {
                                color: var(--Secondary-2, #14438E);
                                /* Regular */
                                font-family: GothamRegular;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }

                    .time_line {
                        padding: 0;
                        margin-top: 32px;

                        .timelineItem_root {
                            min-height: 10px;
                        }

                        .missing_opposite_content::before {
                            flex: 0;
                            padding: 0;
                        }
                    }
                }

                .Mui_accordion_details_root {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                .less {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    img {
                        padding-right: 4px;
                    }

                    span {
                        color: var(--Secondary-2, #14438E);
                        /* Regular */
                        font-family: GothamRegular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

        }

    }
}

@media screen and (max-width: 680px) {
    .root {
        .main {
            padding: 30px;

            .transports_history {
                .accordion {
                    padding: 0px;
                    .transport_main {
                        .transport {
                            display: flex;
                            flex-direction: column;

                            .time {
                                display: flex;
                                flex-direction: row;
                                gap: 14px;
                            }
                        }

                        .more_data {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;

                            .more {
                                display: flex;
                                justify-content: flex-start;
                            }
                        }

                    }
                }
            }
        }
    }
}