/* this file added by Bearer Web Developer  */
.account_form {
    .input {
        margin-top: 26px;
        margin-bottom: 26px;
    }

    
    .password {
        margin-top: 23px;
        display: flex;
        gap: 20px;
    }
    .privacy {
        margin-top: 26px;
        margin-bottom: 11px;
    }
    .business_customer {
        margin-top: 11px;
        margin-bottom: 26px;
    }

    .button {
        width: 100%;
    
        border-radius: 10px;
        background: var(--Secondary, #14438E);
        padding: 12px;
        &:disabled {
            opacity: 0.5;
        }
        span {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }

        &:active {
            outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }

}