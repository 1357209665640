/* this file added by Bearer Web Developer  */
.account_form {
    .details {
        h6 {
            margin-top: 26px;
            margin-bottom: 18px;
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        .name,
        .extra_data,
        .post_code {
            margin-top: 18px;
            display: flex;
            gap: 16px;
        }


    }

    .button[type='submit'] {
        width: 100%;
        margin-top: 22.72px;
        border-radius: 10px;
        background: var(--Secondary, #14438E);
        padding: 12px;

        &:disabled {
            opacity: 0.5;
        }

        span {
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }

        &:active {
            outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }

}