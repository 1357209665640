/* this file added by Bearer Web Developer  */
.show_steps {
    border-radius: 10px;
    background: var(--secondary-2-hsb-66, #184FA8);
    padding: 20px 12px 20px 12px;
    margin-top: 21px;
    margin-bottom: 19px;
    transition: all;

    .main_bar {
        border-radius: 17px;
        background: var(--secondary-2-hsb-82, #1D63D1);
        height: 3px;
        width: 100%;
        position: relative;
        align-items: center;
        display: flex;

        .main_cycle_one {
            position: absolute;
            left: 0;
        }

        .active_cycle_one {
            position: absolute;
            left: 0;
            display: none;
        }

        .active_cycle_one_end {
            position: absolute;
            left: 0;
            display: none;
        }

        .main_cycle_two {
            position: absolute;
            left: 33.33%;
        }

        .active_cycle_two {
            position: absolute;
            left: 33.33%;
            display: none;
        }

        .active_cycle_two_end {
            position: absolute;
            left: 33.33%;
            display: none;
        }

        .main_cycle_three {
            position: absolute;
            left: 66.66%;
        }

        .active_cycle_three {
            position: absolute;
            left: 66.66%;
            display: none;
        }

        .active_cycle_three_end {
            position: absolute;
            left: 66.66%;
            display: none;
        }

        .main_cycle_four {
            position: absolute;
            right: 0;
        }

        .active_cycle_four {
            position: absolute;
            right: 0;
            display: none;
        }

        .active_cycle_four_end {
            position: absolute;
            right: 0;
            display: none;
        }

        .active_bar {
            position: absolute;
            left: 0;
            border-radius: 17px;
            background: var(--primary-hsb-80100, #32B1FF);
            height: 3px;
            width: 0%;
        }
    }

    .step_one {
        .active_cycle_one {
            display: block;
        }

        .active_bar {
            width: 0%;
        }
    }

    .step_two {
        .active_cycle_one {
            display: block;
        }

        .active_cycle_two {
            display: block;
        }

        .active_bar {
            width: 34%;
        }
    }

    .step_three {
        .active_cycle_one {
            display: block;
        }

        .active_cycle_two {
            display: block;
        }

        .active_cycle_three {
            display: block;
        }

        .active_bar {
            width: 68%;
        }
    }

    .step_four {
        .active_cycle_one {
            display: block;
        }

        .active_cycle_two {
            display: block;
        }

        .active_cycle_three {
            display: block;
        }

        .active_cycle_four {
            display: block;
        }

        .active_bar {
            width: 100%;
        }
    }

    .step_five {
        .active_cycle_one_end {
            display: block;
        }

        .active_cycle_two_end {
            display: block;
        }

        .active_cycle_three_end {
            display: block;
        }

        .active_cycle_four_end {
            display: block;
        }

        .active_bar {
            border-radius: 17px;
            background: var(--Primary-2, #FF8A29);
            width: 100%;
        }
    }

}