/* this file added by Bearer Web Developer  */
.message_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 19px;
    img {
        margin-right: 8px;
    }

    p {
        margin: 0;
        color: var(--neutral-hsb-96, #F5F5F5);

        /* Bold */
        font-family: GothamBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 114.286% */
    }
}