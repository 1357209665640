/* this file added by Bearer Web Developer  */

.icon_gray {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 11px;
    background: var(--neutral-hsb-74, #BDBDBD);
}
.icon_blue {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 11px;
    background: var(--secondary-2-hsb-70100, #4E92FF);
}
.checked_icon_gray {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 11px;
    background: var(--neutral-hsb-74, #BDBDBD);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        border-radius: 11px;
        background: var(--neutral-hsb-30, #4D4D4D);
        width: 12px;
        height: 12px;
        flex-shrink: 0;
    }
}
.checked_icon_blue {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 11px;
    background: var(--secondary-2-hsb-70100, #4E92FF);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        border-radius: 11px;
        background: var(--neutral-hsb-89, #E3E3E3);
        width: 12px;
        height: 12px;
        flex-shrink: 0;
    }
}
.label_gray {
    color: var(--neutral-hsb-30, #4D4D4D);
    /* Regular */
    font-family: GothamRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.label_blue {
    color: var(--neutral-hsb-96, #F5F5F5);
    /* Regular */
    font-family: GothamRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
