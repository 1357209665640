/* this file added by Bearer Web Developer  */
.mode_of_transport {
    width: 100%;
    padding: 14px;
    border-radius: 6px;
    background: var(--neutral-hsb-89, #E3E3E3);

    h4 {
        margin-top: 0px;
        margin-bottom: 11.84px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Bold */
        font-family: GothamBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 114.286% */
    }

    .modes {
        display: grid;
        gap: 9.45px;
        grid-template-columns: 1fr 1fr 1fr;
        .mode_type {
            opacity: 0.5;
            border-radius: 7.266px;
            background: var(--Secondary-2, #14438E);
            width: 100%;
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                max-height: 36px;
                height: 100%;
            }
        }
        .active_mode_type {
            opacity: 1;
        }
    }

}