/* this file added by Bearer Web Developer  */
.setting_popup {
    width: 100%;
    max-width: 468px;
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 38px;

        h5 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }
        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;
            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }

    }

    h6 {
        margin-top: 26px;
        margin-bottom: 16px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .save {
        margin-top: 44px;
        width: 100%;
        padding: 12px;
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        border-radius: 10px;
        background: var(--secondary-2-hsb-29, #0A234A);

        /* 162.5% */
        &:disabled {
            color: var(--neutral-hsb-96, #F5F5F5);
            opacity: 0.5;
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }
}

@media screen and (max-width: 680px) {
    .setting_popup {
        padding: 30px;
    }
}