/* this file added by Bearer Web Developer  */
.tooltip {
    background-color: white !important;
    color: var(--neutral-hsb-30, #4D4D4D) !important;
    /* Bold */
    font-family: GothamBold !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.arrow {
    color: white !important;
}

.transport_info_container {
    margin: 20px 16px 24px 16px;

    .transport_progress {
        margin-top: 24px;

        .cancel {
            border-radius: 10px;
            border: 2px solid var(--Style, #DF402A);
            width: 100%;
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
            padding-top: 16.4px;
            padding-bottom: 16.4px;
        }

        .call {
            display: flex;
            align-items: center;
            padding: 17px 18px;
            border-radius: 10px;
            background: var(--secondary-2-hsb-66, #184FA8);
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;

            /* 162.5% */
            img {
                margin-right: 8px;
            }
        }
    }

    .rider_info {

        display: flex;
        gap: 20px;
        justify-items: center;

        .image {
            border-radius: 10px;
            background: var(--secondary-2-hsb-66, #184FA8);
            width: 100%;
            // max-width: 123px; // for 354px max-width left side
            max-width: 190px; // for 500px max-width left side

            .image_box {
                margin-left: 11px;
                margin-right: 11px;
                margin-top: 18px;
                margin-bottom: 14px;
                display: flex;
                justify-content: center;

            }

            p {
                margin-left: 11px;
                margin-right: 11px;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .type_riding {
            border-radius: 10px;
            background: var(--secondary-2-hsb-66, #184FA8);
            width: 100%;

            p {
                margin-top: 16px;
                margin-bottom: 12px;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .motorbike_registration {
                margin: 12px 22px 15px 22px;
                border-radius: 10px;
                border: 2px solid var(--Primary-2, #FF8A29);

                h5 {
                    margin-top: 6px;
                    margin-bottom: 1px;
                    color: var(--neutral-hsb-96, #F5F5F5);
                    text-align: center;
                    font-family: Gotham;
                    font-size: 24.727px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40.182px;
                    /* 162.5% */
                }

                h6 {
                    margin-top: 1px;
                    margin-bottom: 11px;
                    color: var(--neutral-hsb-96, #F5F5F5);
                    text-align: center;
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

            }
        }

        .not_rider {
            width: 100%;
            border-radius: 10px;
            background: var(--secondary-2-hsb-66, #184FA8);
            padding: 23px 17px 28px 17px;
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;

            h5 {
                margin: 0;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: right;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                /* 143.75% */
            }
        }
    }

    .secret_code {
        margin-top: 20px;
        margin-bottom: 27px;
        border-radius: 10px;
        background: var(--secondary-2-hsb-66, #184FA8);
        padding: 6px 14px;
        display: flex;
        justify-content: space-between;

        .code {
            display: flex;


            p {
                margin: 8px;
                margin-right: 19px;
                color: var(--neutral-hsb-96, #F5F5F5);
                /* Regular */
                font-family: GothamRegular;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .code_data {
                display: flex;
                gap: 4px;
                align-items: center;

                span {
                    padding: 1px 1px;
                    border-radius: 6px;
                    background: var(--Primary-3, #14438E);
                    color: var(--neutral-hsb-96, #F5F5F5);
                    font-family: GothamBold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .copy {
            cursor: pointer;
            padding: 0px 3px;
            border-radius: 6px;
            background: var(--primary-3-hsb-70100, #4C91FF);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .order_number {
        margin-top: 20px;
        margin-bottom: 27px;
        border-radius: 10px;
        background: var(--secondary-2-hsb-66, #184FA8);
        padding: 6px 14px;
        display: flex;
        justify-content: space-between;

        p {
            margin: 8px;
            margin-right: 19px;
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .copy {
            cursor: pointer;
            padding: 0px 3px;
            border-radius: 6px;
            background: var(--primary-3-hsb-70100, #4C91FF);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}

.time_line {
    border-radius: 10px;
    background: var(--secondary-2-hsb-66, #184FA8);

    padding: 16px;
    margin-top: 32px;
    margin-bottom: 20px;

    .content {
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .timelineItem_root {
        min-height: 10px;
    }

    .missing_opposite_content::before {
        flex: 0;
        padding: 0;
    }
}

.chat_box {
    overflow: hidden;
    .loading {
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--neutral-hsb-96, #F5F5F5);
    }
    .message_box {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: var(--neutral-hsb-96, #F5F5F5);
        height: 174px;
        overflow: auto;
        padding: 8px;

        .my_message_box_part {
            display: flex;
            justify-content: end;
            margin-top: 8px;
            margin-bottom: 8px;

            .image_part {
                display: flex;
                flex-direction: column;
                justify-content: end;

                .image {
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    flex-shrink: 0;
                    overflow: hidden;
                }
            }

            .arrow {
                border-left: 0px solid transparent;
                border-right: 9px solid transparent;
                border-bottom: 8px solid #1DA3F6;
            }

            .my_message_box {
                max-width: fit-content;

                .my_message {
                    padding: 4px;
                    border-radius: 10px 10px 0px 10px;
                    background: var(--primary-linear, linear-gradient(218deg, #009EFF 2.84%, #46AAE8 101.77%));

                    p {
                        word-wrap: break-word;
                        word-break: break-all;
                        margin: 0;
                        padding: 0;
                        color: var(--neutral-hsb-96, #F5F5F5);
                        /* Regular */
                        font-family: GothamRegular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .my_message_time {
                        margin: 0;
                        padding-top: 8px;
                        text-align: left;
                        color: #FFF;
                        font-family: Gotham;
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

            }


        }

        .rider_message_box_part {
            display: flex;
            justify-content: start;
            margin-top: 8px;
            margin-bottom: 8px;

            .image_part {
                display: flex;
                flex-direction: column;
                justify-content: end;

                .image {
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    flex-shrink: 0;
                    overflow: hidden;
                }

            }

            .arrow {
                border-left: 9px solid transparent;
                border-right: 0px solid transparent;
                border-bottom: 8px solid var(--ff-8-a-29, #FF8A29);
            }


            .rider_message_box {
                max-width: fit-content;

                .rider_message {
                    border-radius: 10px 10px 10px 0px;
                    background-color: var(--ff-8-a-29, #FF8A29);
                    padding: 4px;

                    .name {
                        padding-bottom: 4px;
                        color: #FFF;
                        text-align: left;
                        font-family: Gotham;
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    p {
                        word-wrap: break-word;
                        word-break: break-all;
                        padding: 0;
                        margin: 0;
                        color: var(--neutral-hsb-96, #F5F5F5);
                        /* Regular */
                        font-family: GothamRegular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .rider_message_time {
                        padding-top: 8px;
                        margin: 0;
                        text-align: right;
                        color: #FFF;
                        font-family: Gotham;
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }


                }

            }

        }

        .admin_message_box_part {
            display: flex;
            justify-content: start;
            margin-top: 8px;
            margin-bottom: 8px;

            .image_part {
                display: flex;
                flex-direction: column;
                justify-content: end;

                .image_support {
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    background-color: #34B206;
                    flex-shrink: 0;
                }
            }

            .arrow {
                border-left: 9px solid transparent;
                border-right: 0px solid transparent;
                border-bottom: 8px solid #34B206;
            }

            .admin_message_box {
                max-width: fit-content;

                .admin_message {

                    border-radius: 10px 10px 10px 0px;
                    background: #34B206;
                    padding: 4px;

                    .support {
                        padding-bottom: 4px;
                        color: #FFF;
                        text-align: left;
                        font-family: Gotham;
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    p {
                        word-wrap: break-word;
                        word-break: break-all;
                        padding: 0;
                        margin: 0;
                        color: var(--neutral-hsb-96, #F5F5F5);
                        /* Regular */
                        font-family: GothamRegular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .admin_message_time {
                        padding-top: 8px;
                        margin: 0;
                        text-align: right;
                        color: #FFF;
                        font-family: Gotham;
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                }

            }


        }

    }

    .type_box {
        padding: 8px;
        position: relative;
        display: flex;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background: var(--neutral-hsb-96, #F5F5F5);
        box-shadow: 0px -20px 20px 1px #fff;

        .MuiOutlinedInput-notchedOutline {

            border-color: "green";


            &:hover {
                border-color: "red";
            }

            &:focus {
                border-color: "purple";
            }

        }

        .field {
            width: 100%;

            .text_field {
                background: var(--neutral-hsb-89, #E3E3E3);
                width: 100%;
                height: 42px;
                padding: 12px 14px;

            }
        }

        .send_part {
            display: flex;
            justify-content: center;
            align-items: center;

            .send {
                margin-left: 12px;
                width: 42px;
                height: 42px;
                border-radius: 10px;
                background: var(--neutral-hsb-89, #E3E3E3);
                min-width: unset;
            }
        }
    }
}

@media screen and (max-width: 834px) {
    .transport_info_container {
        .transport_progress {
            .cancel {
                margin-bottom: 84px;
            }
        }
    }
}