// this file added by Bearer Web Developer
.custom_checkbox {
    input {
        display: none;
    }
    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        .checkbox {
            margin-right: 10px;
            border-radius: 10px;
            background: #a1c3fa;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            .checked {
                border-radius: 5px;
                background: var(--Secondary-2, #14438E);
                width: 15.2727px;
                height: 15.2727px;
            }
        }

    }
}