/* this file added by Bearer Web Developer  */
.root {
    padding: 44px;
    border-radius: 10px;
    width: 445px;
    max-width: 100%;

    .main {
        width: 100%;
        p {
            margin-top: 8px;
            margin-top: 26px;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;

        }
        .buttons {
            display: flex;
            gap: 20px;
            .button {
                width: 100%;
                margin-top: 22.72px;
                border-radius: 10px;
                background: var(--Secondary, #14438E);
                padding: 12px;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
        }

    }
}


@media screen and (max-width: 680px) {
    .root {
        padding: 30px;

    }
}