.currentIcon {
    position: absolute;
    bottom: 38px;
    right: 38px;
    font-Size: 40px;
    cursor: pointer;
    width: 48px;
    height: 48px;

    &:active {
        fill: #0A234A;
    }
}

.mapContainerClassName {
    border-radius: 0px;
}

@media screen and (max-width: 834px) {
    .mapContainerClassName {
        border-radius: 20px;
    }
}