/* added index.css file by Bearer Web Developer  */

/* fonts start*/
@font-face {
  font-family: gotham;
  src: url('./fonts/gotham/gotham_book.otf');
}

@font-face {
  font-family: "GothamBold";
  src: url("./fonts/GothamBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Medium.woff2") format("woff2"),
    url("./fonts/Gotham-Medium.woff") format("woff"),
    url("./fonts/Gotham-Medium.ttf") format("truetype"),
    url("./fonts/Gotham-Medium.svg#Gotham") format("svg");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "GothamLight";
  src: url("./fonts/Gotham-Light.woff") format("woff"),
    url("./fonts/Gotham-Light.ttf") format("truetype"),
    url("./fonts/Gotham-Light.svg#Gotham") format("svg"),
    url("./fonts/Gotham-Light.otf") format("otf");
  font-display: swap;
}

@font-face {
  font-family: "GothamRegular";
  src: url("./fonts/Gotham-Regular.woff") format("woff"),
    url("./fonts/Gotham-Regular.ttf") format("truetype"),
    url("./fonts/Gotham-Regular.svg#Gotham") format("svg");
  font-display: swap;
}

/* GothamBook font*/
@font-face {
  font-family: 'GothamBook';
  src: url("./fonts/font-gotham-book/GothamBook.woff") format("woff"),
    url("./fonts/font-gotham-book/GothamBook.ttf") format("truetype"),
    url("./fonts/font-gotham-book/GothamBook.svg#Gotham") format("svg"),
    url("./fonts/font-gotham-book/GothamBook.otf") format("otf");
  font-display: swap;
}

/* fonts end*/

/* scroll customization start*/
/* width */
::-webkit-scrollbar {
  width: 8px;
  background: white;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: var(--Primary-2, #FF8A29);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c95a00;
}

/* scroll customization end */