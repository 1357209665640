/* this file added by Bearer Web Developer  */

    .congratulations {
        width: 100%;
        header {

            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
                margin: 0;
                color: var(--Secondary-2, #14438E);
                /* Bold */
                font-family: GothamBold;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                /* 133.333% */
            }

        }

        p {
            margin-top: 7px;
            margin-bottom: 0px;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
        }
        .button {
            width: 100%;
            margin-top: 22.72px;
            border-radius: 10px;
            background: var(--Secondary, #14438E);
            padding: 12px;
    
            &:disabled {
                opacity: 0.5;
            }
    
            span {
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }
    
            &:hover {
                background: var(--secondary-2-hsb-29, #0A234A);
            }
    
            &:active {
                outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
                background: var(--secondary-2-hsb-29, #0A234A);
            }
        }
    }

