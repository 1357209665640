/* this file added by Bearer Web Developer  */
.profile_part {
    margin-right: 38px;

    .bar {
        display: none;
    }

    .profile_button {

        padding: 4px 10px;
        border-radius: 10px;
        background: var(--neutral-hsb-89, #E3E3E3);

        .profile {
            display: flex;
            justify-content: center;
            align-items: center;

            .profile_person {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 35px;
                height: 35px;
                flex-shrink: 0;
                margin-right: 10px;
                background-color: white;
            }

            span {
                padding-top: 11px;
                padding-bottom: 10px;
                color: var(--Secondary-2, #14438E);
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }

            .expand_more {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 13px 8px 10px 2px;

                .open {
                    rotate: 180deg;
                }
            }
        }

        &:hover {
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);

            .profile {
                .profile_person {
                    background-color: var(--secondary-2-hsb-70100, #4E92FF);

                    svg path {
                        fill: #EDEDED;
                    }
                }

                .expand_more {
                    svg path {
                        fill: #F5F5F5;
                    }
                }

                span {
                    color: var(--neutral-hsb-96, #F5F5F5);
                }
            }
        }
    }

}

.menu_item {
    span.text {
        padding: 18px 0;
        width: 100%;
        border-bottom: 2px solid rgba(189, 189, 189, 0.5);
        color: var(--Secondary-2, #14438E);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    span.text_end {
        padding: 18px 0;
        width: 100%;
        color: var(--Secondary-2, #14438E);
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


@media screen and (max-width: 834px) {
    .profile_part {
        margin-right: 0px;

        .profile_button {
            padding: 26.5px 10px;
            border-radius: 0px 0px 10px 0px;
            z-index: 1;
            width: 100%;
            &:hover {
                border-radius: 0px 0px 10px 0px;
            }

            .profile {
                .expand_more {
                    display: none;
                }
            }
        }

        .bar {
            position: relative;
            top: -10px;
            display: block;
            border-radius: 0px 0px 10px 0px;
            background: var(--secondary-2-linear, linear-gradient(269deg, #14438E 26.44%, #2768CF 103.92%));
            height: 23px;
        }
    }
}