/* this file added by Bearer Web Developer  */
.root {
    width: 542px;
    padding: 44px;
}

.change_password {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }
    }

    p {
        margin: 0;
        margin-top: 8px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }
    .repeat_password_input,.password_input {
        padding-top: 20px;
    }

    .submit {
        margin-top: 38px;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        width: 100%;
        padding: 12px;
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;

        /* 162.5% */
        &:disabled {
            background: var(--Secondary-2, #14438E);
            color: var(--neutral-hsb-96, #F5F5F5);
            opacity: 0.5;
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }
}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px; 
    }
}