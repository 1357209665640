/* this file added by Bearer Web Developer  */
.show_sort_basis {
    margin-top: 20px;
    margin-bottom: 20px;
    .sort {
        border-radius: 5px;
        background: var(--neutral-hsb-85, #D9D9D9);
        padding: 3px 5px;
        
        img {
            margin-right: 3px;
        }
    }
}

