/* this file added by Bearer Web Developer  */

.accordion {
    box-shadow: none;

    .accordion_details_root {
        background: var(--neutral-hsb-93, #EDEDED);
    }

    .expand_icon {
        margin: 0;
    }
    .MuiAccordionSummary_root,
    .MuiAccordionSummary_content {
        // display: unset;
        display: grid;
        grid-template-columns: 1fr;
        background: var(--neutral-hsb-93, #EDEDED);
    }

    .expand_more {
        min-width: unset;
        display: flex;
        border-radius: 5px;
        padding: 4px;
        background: var(--neutral-hsb-74, #BDBDBD);

        &:hover {
            background: var(--neutral-hsb-74, #BDBDBD);
        }
    }

    .more_details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--neutral-hsb-93, #EDEDED);

        .details {
            margin-bottom: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .rider {
                display: flex;
                align-items: center;

                .image {
                    margin-right: 12px;
                    max-width: 54px;
                    max-height: 54px;
                }
            }

            .riding {
                display: flex;
                align-items: center;

                .motorbike_registration {
                    margin-right: 14.68px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 4.645px 10.276px 8.516px 10.276px;
                    border-radius: 7.742px;
                    border: 1.548px solid var(--neutral-hsb-30, #4D4D4D);

                    h6 {
                        margin: 0;
                        color: var(--neutral-hsb-30, #4D4D4D);
                        text-align: center;
                        font-family: Gotham;
                        font-size: 19.144px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 31.109px;
                        /* 162.5% */
                    }

                    p {
                        color: var(--neutral-hsb-30, #4D4D4D);
                        text-align: center;
                        font-family: Gotham;
                        font-size: 10.839px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                    }
                }

                .motorbike_model {
                    margin: 0;
                    color: var(--neutral-hsb-30, #4D4D4D);
                    text-align: center;
                    /* Regular */
                    font-family: GothamRegular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        // .expand_more {
        //     min-width: unset;
        //     display: flex;
        //     border-radius: 5px;
        //     padding: 4px;
        //     background: var(--neutral-hsb-74, #BDBDBD);
        // }

        .expand_more_expanded {
            min-width: unset;
            rotate: 180deg;
            display: flex;
            border-radius: 5px;
            padding: 4px;
            background: var(--neutral-hsb-74, #BDBDBD);

        }
    }

    .Mui_accordion_details_root {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
    }

    .map_style {
        border-radius: 20px;
        border: 2px solid var(--secondary-2-hsb-70100, #4E92FF);
        position: relative;
        height: 150px;
        display: block;
        overflow: auto;
    }


}

@media screen and (max-width: 680px) {

    .accordion {
        .more_details {
            .details {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                .rider {
                    margin-bottom: 19px;
                }
            }
        }

    }

}