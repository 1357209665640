/* this file added by Bearer Web Developer  */
.order {
    border-radius: 10px;
    background: var(--neutral-hsb-93, #EDEDED);
    padding: 26px;
    margin-bottom: 18px;
    .header {
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }

        span {
            color: var(--neutral-hsb-52, #858585);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .management_part {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}


@media screen and (max-width: 680px) {
    .order {
        .management_part {
            flex-direction: column;
            justify-content: center;
        }
    }
}