/* this file added by Bearer Web Developer  */
.root {
    padding: 44px 44px 47px 44px;
    width: 542px;
    max-width: 100%;
    border-radius: 10px;
    background: var(--neutral-hsb-96, #F5F5F5);

    .header {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;

            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }

    }

    p {
        margin-top: 8px;
        margin-bottom: 6px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    form {
        .email_input,.password_input {
            padding-top: 20px;
        }
        .login {
            margin-top: 26px;
            display: flex;
            width: 100%;
            height: 50px;
            padding: 12px 0px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 10px;
            background: var(--Secondary-2, #14438E);
            color: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;

            /* 162.5% */
            &:disabled {
                opacity: 0.5;
            }

            &:hover {
                background: var(--Secondary-2, #0a234c);
            }

            &:active {
                outline: 2px solid var(--secondary-2-hsb-66, #184FA8);
                background: var(--secondary-2-hsb-29, #0A234A);
            }
        }


        .or {
            margin-top: 25px;
            display: flex;
            align-items: center;
            gap: 18px;

            .left_line {
                width: 100%;
                height: 7px;
                border-radius: 22px;
                background: linear-gradient(270deg, #BDBDBD 2.78%, rgba(189, 189, 189, 0.00) 100%);
            }

            .or_text {
                color: var(--neutral-hsb-30, #4D4D4D);
                /* Bold */
                font-family: GothamBold;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .right_line {
                width: 100%;
                height: 7px;
                border-radius: 22px;
                background: linear-gradient(270deg, #BDBDBD 2.78%, rgba(189, 189, 189, 0.00) 100%);
                rotate: 180deg;
            }
        }

        .signup {
            margin-top: 27px;
            width: 100%;
            border-radius: 10px;
            background: var(--secondary-2-hsb-82, #1D63D1);
            position: relative;

            button {
                padding: 12px;
                display: block;
                width: 100%;
                color: var(--neutral-hsb-96, #F5F5F5);
                text-align: center;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
                text-decoration: none;
            }
        }
    }

}

@media screen and (max-width: 680px) {
    .root {
        padding: 30px;
    }
}