/* this file added by Bearer Web Developer  */

.cancel_order {
    max-width: 468px;
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        h3 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }
        .cancel {
            padding: 0;
            justify-content: end;
            min-width: unset;
            &:hover {
                svg path {
                    fill: #4D4D4D;
                }
            }
        }


    }

    p {
        margin: 0;
        margin-bottom: 32px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;

        /* 162.5% */
        span {
            color: var(--Style, #DF402A);
        }
    }

    h5 {
        margin: 0;
        margin-bottom: 20px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .text {
        margin-top: 20px;
        width: 100%;
    }

    .text_input {
        min-height: 141px;
    }

    .submit {
        margin-top: 26px;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
        &:disabled {
            color: var(--neutral-hsb-96, #F5F5F5);
            opacity: 0.5;
        }
        &:hover {
            background: var(--Secondary-2, #14438E);
            color: var(--neutral-hsb-96, #F5F5F5);
        }
    }
}

@media screen and (max-width: 680px) {
    .cancel_order {
        padding: 30px;
    }
}