/* this file added by Bearer Web Developer  */
.locations {
    width: 100%;
    grid-column: 2 / span 2;

    .time_line {
        padding: 0;

        .timelineItem_root {
            min-height: 10px;
        }

        .missing_opposite_content::before {
            flex: 0;
            padding: 0;
        }

        .timeline_content_root {
            padding: 0px;
            padding-left: 10px;
        }

        .timeline_content {
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            p {
                margin: 0px;
                margin-bottom: 8px;
                color: var(--neutral-hsb-30, #4D4D4D);
            }

            span {
                color: var(--neutral-hsb-52, #858585);
            }
        }

        .destination_edit {
            padding-left: 10px;
        }
    }
}