/* this file added by Bearer Web Developer  */

.text_input {
    width: 100%;

    .label {
        margin-bottom: 8px;
        color: var(--neutral-hsb-30, #4D4D4D);
        /* Bold */
        font-family: GothamBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .input_part {
        position: relative;

        input {
            border: none;
            border-radius: 10px;
            height: 48px;
            padding: 19px 91px 19px 14px;
            width: 100%;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.84px;
        }

        .copy {
            position: absolute;
            right: 12px;
            top: calc(50% - 16px);
            border-radius: 8px;
            background: var(--neutral-hsb-85, #D9D9D9);
            padding: 4px 7px 4px 5px;
            .copy_button_label {
                svg {
                    margin-right: 4px;
                }
            }
        }
    }

    .gray {
        input {
            color: var(--Secondary-2, #14438E);
            background: var(--neutral-hsb-89, #E3E3E3);
        }
        .copy {
            background: var(--neutral-hsb-85, #D9D9D9);
        }
    }

    .green {
        input {
            color: var(--neutral-hsb-30, #4D4D4D);
            background: rgba(22, 151, 27, 0.21);
        }
        .copy {
            background: var(--Neutral, #FFF);
        }

    }

}