/* this file added by Bearer Web Developer  */
.root {
    .confirm_part {
        margin: 20px 16px 24px 16px;

        .confirm {
            width: 100%;
            padding: 18px 8px;
            border-radius: 10px;
            color: var(--neutral-hsb-96, #F5F5F5);
            background: var(--Primary, #009EFF);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }

        .confirm:disabled {
            width: 100%;
            padding: 18px 8px;
            border-radius: 10px;
            background: linear-gradient(180deg, #184FA8 0%, rgba(24, 79, 168, 0.00) 100%);
            color: var(--neutral-hsb-96, #F5F5F5);
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */

        }
        .confirm_free_order,.confirm_free_order:disabled {
            margin-top: 20px;
            margin-bottom: 50px;
            background: var(--Style, #16971B);
        }
        .confirm_discounted {
            width: 100%;
            padding: 18px 8px;
            border-radius: 10px;
            background: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            .real_price {
                margin: 0;
                color: var(--neutral-hsb-52, #858585);
                text-align: right;
                /* Light */
                font-family: GothamLight;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                text-decoration: line-through;
                margin-right: 7px;
            }

            .discounted_text {
                margin-right: 7px;
                color: var(--Red, #DF402A);
                /* Regular */
                font-family: Gotham;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .discounted_price {
                margin: 0;
                color: var(--16971-b, #16971B);
                text-align: right;
                /* Bold */
                font-family: GothamBold;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                /* 162.5% */
            }

        }

        .confirm_undiscounted {

            padding: 18px 8px;
            width: 100%;
            border-radius: 10px;
            color: var(--16971-b, #16971B);
            background: var(--neutral-hsb-96, #F5F5F5);
            text-align: center;
            /* Bold */
            font-family: GothamBold;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            /* 162.5% */
        }
    }
}

@media screen and (max-width: 834px) {
    .root {
        .confirm_part {
            margin-bottom: 84px;
        }
    }
}