/* this file added by Bearer Web Developer  */
.paper_width_sm {
    max-width: 678px;
    width: 100%;
}

.favourite_places_form {
    padding: 44px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        h5 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }
    }

    .map {
        height: 169px;
        border-radius: 20px;
    }
    .address_field {
        margin-top: 25px;
    }
    .place {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        .place_input,.place_details_input {
            padding-top: 20px;
        }
    }

    .add {
        margin-top: 30px;
        width: 100%;
        padding: 12px;
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        border-radius: 10px;
        background: var(--secondary-2-hsb-29, #0A234A);

        /* 162.5% */
        &:disabled {
            color: var(--neutral-hsb-96, #F5F5F5);
            opacity: 0.5;
        }

        &:hover {
            background: var(--secondary-2-hsb-29, #0A234A);
        }
    }
}

@media screen and (max-width: 680px) {
    .favourite_places_form {
        padding: 30px;
    }
}