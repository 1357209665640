/* this file added by Bearer Web Developer  */
.free_delivery {

    .header {
        display: flex;
        justify-content: space-between;

        h5 {
            margin: 0;
            color: var(--Secondary-2, #14438E);
            /* Bold */
            font-family: GothamBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }

        img {
            width: 28px;
            height: 28px;
            cursor: pointer;
        }
    }

    p {
        margin-top: 16px;
        color: var(--Secondary-2, #14438E);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;

        /* 162.5% */
        span {
            color: var(--Style, #16971B);
        }
    }
    .cover {
        border-radius: 10px;
        background: var(--secondary-hsb-70100, #4C91FF);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;

        img {
            position: relative;
            top: 40px;
        }
    }

    .submit {
        margin-top: 26px;
        display: flex;
        width: 100%;
        height: 50px;
        padding: 12px 0px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--Secondary-2, #14438E);
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: center;
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .submit:hover {
        background: var(--secondary-2-hsb-29, #0A234A);
    }
}