/* this file added by Bearer Web Developer  */
.name {

    p {
        margin: 0px;
        display: flex;
        align-items: center;
        img {
            margin-right: 9px;
        }

        span {
            color: var(--neutral-hsb-52, #858585);
            /* Regular */
            font-family: GothamRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}