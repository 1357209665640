/* this file added by Bearer Web Developer  */
.api_requests_origin {
    padding: 14px;
    border-radius: 6px;
    background: var(--neutral-hsb-89, #E3E3E3);
    width: 100%;

    .header {
        display: flex;
        justify-content: space-between;

        h3 {
            color: var(--neutral-hsb-30, #4D4D4D);
            /* Bold */
            font-family: GothamBold;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

    }

     .list {
        margin-top: 10px;
        ul {
            padding: 0;
            margin: 0;
            max-height: 114px;
            overflow-y: auto;
            li {
                display: flex;
                align-items: center;
                color: var(--neutral-hsb-15, #262626);
                margin-bottom: 15px;
                /* Light */
                font-family: GothamLight;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                list-style-type: none;

                &:last-child {
                    margin-bottom: 0px;
                }

                span.delete {
                    border-radius: 4px;
                    background: var(--neutral-hsb-74, #BDBDBD);
                    padding: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}