/* this file added by Bearer Web Developer  */
.root {
    margin: 16px;
    padding: 18px;
    border-radius: 10px;
    background: var(--secondary-2-hsb-82, #1D63D1);
    .header {
        display: flex;
        justify-content: space-between;
    }
    h2 {
        margin: 0;
        padding: 0;
        color: var(--neutral-hsb-96, #F5F5F5);
        /* Bold */
        font-family: GothamBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 162.5% */
    }

    .edit {
        color: var(--neutral-hsb-96, #F5F5F5);
        text-align: right;
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .type {
        display: flex;
        align-items: center;
        color: var(--neutral-hsb-96, #F5F5F5);
        margin-top: 25px;
        /* Regular */
        font-family: GothamRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .image {
            margin-right: 9px;
            height: 100%;
            max-height: 55px;
        }
    }
}